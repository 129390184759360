.container {
    composes:container from '../../../pages/app/app.css';
}

.row_container * {
    font-family: 'Montserrat', sans-serif;
}

.row_container {
    display: flex;
    justify-content: space-between;
    padding: 60px 0 10px;
}

.title_category{
    display: flex;
    font-weight: bold;
    font-size: 32px;
    margin: 0;
    margin-bottom: 20px;
    line-height: 29px;
    align-items: center;
}
.favourites img:hover{
    cursor: pointer;
}


@media only screen and (max-width: 1110px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        /* max-width: 1024px; */
        width: 100%;
        /* padding: 0 15px; */
    }
}

@media only screen and (max-width: 970px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        max-width: 950px;
        width: 100%;
    }
}


@media (max-width:900px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        max-width: 890px;
        width: 100%;
    }
}


@media only screen and (max-width: 850px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        padding: 0 70px;
        margin: 0 auto;
    }
}


@media only screen and (max-width: 768px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        width: 100%;
    }
}


@media only screen and (max-width: 550px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        padding: 0 70px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 425px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        padding: 0 15px;
    }
    .title{
        font-size: 18px;
    }
}


@media only screen and (max-width: 375px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        padding: 0 15px;
    }
    .title{
        font-size: 18px;
    }
}

@media only screen and (max-width: 320px) {
    .container {
        composes:container from '../../../pages/app/app.css';
        padding: 0 15px;
    }
    .title{
        font-size: 18px;
    }
}

