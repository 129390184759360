.container {
  position: relative;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  user-select: none;
  font-family: "Lato", sans-serif;
  overflow: hidden;
}

.carousel {
  position: relative;
  display: flex;
  composes: container from "../../../pages/app/app.css";
  height: 52vh;
}
.slideBox {
  height: 100%;
  width: 100%;
  position: relative;
}

.carouselWay {
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  display: flex;
}

.carouslItem {
  width: 50%;
  height: 100%;
  display: flex;
}


/* Controls */
.control {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  composes: container from "../../../pages/app/app.css";
  width: 100%;
  font-family: "Lato", sans-serif;
}
.info {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.counter {
  display: flex;
  align-items: center;
}

.name {
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 31px;
  color: #ffffff;
  width: fit-content;
  /* display: flex; */
  /* align-items: flex-end; */
}
.name span {
  /* display: inline; */
  font-weight: normal;
  /* margin-bottom:-10px; */
  margin-left: 10px;
  font-size: 14px;
  line-height: 17px;
}
.subInfo {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.genre {
  font-family: "Lato", sans-serif;
  composes: genre from "../../movie/movieInfo/movieInfo.module.css";
  margin-top: 10px;
  align-items: flex-start;
  max-width: 80%;
  position: static;
}
.country {
  margin-right: 10px;
  margin-top: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
}

@media screen and (max-width: 1190px) {
  .control {
    flex-direction: column;
  }
  .counter {
    margin: 0px auto;
    /* margin-top: 0px; */
    margin-bottom: -30px;
  }
  .name span {
    font-size: 10px;
  }
  .genres {
    margin-top: 10px;
  }
  .subInfo {
    align-items: flex-start;
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {
  .control {
    margin-top: 30px;
  }
  .name {
    font-size: 29px;
  }

  .control .counter {
    margin: 0 auto;
  }
}

@media screen and (max-width: 761px) {
  .carousel {
    padding: 0 40px;
  }

  .control {
    padding: 0 40px;
    bottom: 0;
  }

  .name {
    font-size: 20px;
  }

  .country {
    font-size: 16px;
  }
}

@media screen and (max-width: 850px) {
  .carousel {
    padding: 0 70px;
  }

  .control {
    padding: 0 70px;
  }
}

@media screen and (max-width: 460px) {
  .control {
    margin-bottom: 10px;
  }

  .carousel {
    padding: 0 10px;
  }

  .control {
    padding: 0 22px;
  }

  .control .counter {
    margin: 0 auto;
  }

  .counter .buttons {
    margin: 0 40px;
  }
}

