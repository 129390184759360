.container {
    composes: container from "../app/app.css";
    display: flex;
    position: relative;
    font-family: "Lato", sans-serif;
    margin-top: 20px;
    
}

.result {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}