.container{
    display: flex;
    width: 100%;
    position: relative;
    padding: 10px 0px;
    flex-direction: column;
}

.title{
    font-family: LatoRegular;
    padding-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #949494;
    font-family: 'Lato', sans-serif;
}

.input{
    display: flex;
    width: 100%;
    transition: border .4s ease-in-out;
    border: none;
    background: rgba(255, 255, 255, 0.06);
    padding: 10px 10px;
    padding-right: 40px;
    font-size: 19px;
    outline: none;
    font-size: 16px;
    line-height: 18px;
    color: white;
    border-radius: 28px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    font-family: 'Lato', sans-serif;
}

.input::-ms-reveal, .input::-ms-clear{
    display: none;
}

.eye{
    position: absolute;
    right: 0;
    top: 55%;
    transform: translate(-50%, -50%);
}
.eye:hover{
    cursor: pointer;
}

