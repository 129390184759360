.container{
    padding-right: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    position: relative;
    background-color: black;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(25, 23, 32, 0.726);
}

.playerButton{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.trller_video{
    object-fit: cover;
    max-width: 100vw;
}
.trller_video::-internal-media-controls-download-button {
    display:none;
}

.trller_video::-webkit-media-controls-enclosure {
    overflow:hidden;
}

.trller_video::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}
@media (max-width: 425px) {
    .trller_video{
        max-height: 189px;
    }
    .container{
        max-height: 189px;
    }
}
