.container{
    width: 100vw;
    height: 90vh;
}

.backContainer{
    width: 100vw;
    height: 100%;
}

.background{
    position: fixed;
    width: 100%;
    height: 100%;
}

.button{
    width: 100%;
    z-index: 99;
    position: absolute;
    margin-top: -203px;
    justify-content: center;
    display: flex;
}

.button a{
    text-decoration: none;
}