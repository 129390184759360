.seekbar{
    display: flex;
    position: relative;
    width: calc(100% - 250px);
    margin: 0 15px;
}
.seekbar input[type='range']{
    -moz-appearance: none;
    opacity: 0;
    width: 100%;
    -webkit-appearance: none;
    background: #f0f0f0;
    height: 6px;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
}
.seekbar input[type='range']::-webkit-slider-thumb{
    opacity: 1;
    -webkit-appearance: none;
    background: #D7141D;
    height: 13px;
    width: 13px;
    border-radius: 50%;
}

.filled, .seek{
    display: flex;
    align-items: center;
    height: 6px;
    border-radius: 4px;
    position: absolute;
    
    z-index: -1;
}
.filled{
    background-color: #D7141D;
}
.seek{
    width: 100%;
    background-color: #C4C4C4;
}
.filled::after{
    content: '';
    position: absolute;
    right: 0;
    transform: translateX(50%);
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #D7141D;
}