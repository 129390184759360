.navigation{
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
}

.navigation img:hover{
    cursor: pointer;
    box-shadow: 0 0 4px rgb(53, 53, 53);
    background-color: rgb(24, 24, 24);
    border-radius: 50%;
}


.title{
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

.description{
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #A2A2A2;
}