.container {
  composes: container from "../../../pages/app/app.css";
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  color: #fff;
}
.movieWrapper {
  composes: container from "../../../pages/app/app.css";
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.movieIntro {
  display: flex;
}
.movieTitle {
  display: flex;
  width: 100%;
}
.filmBio {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.moviePicture img {
  width: 220px;
  height: 300px;
  border-radius: 10px;
}

.movieInfo {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.movieInfo .moviData {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.movieInfo .moviData .info_text {
  margin-bottom: 14px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #bbbbbb;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

.img_rate {
  display: none;
}

.movieInfo .moviData div {
  height: 22px;
  display: flex;
  align-items: baseline;
}

.movieInfo .moviData div img {
  height: 14px;
  margin-right: 10px;
}

.movieInfo .moviData span {
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.genre {
  position: relative;
  width: 100%;
  height: fi;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: 20px;
  overflow: auto;
}

.genre a {
  display: flex;
  justify-content: center;
  font-style: normal;

  font-size: 14px;
  text-decoration: none;
  margin: 5px 4px;
  color: white;
  padding: 2px 10px;
  font-weight: 400;
  background-color: rgb(32, 32, 32);
  border-radius: 50px;
  line-height: 17px;
}

.genre.dark a {
  background-color: #fff;
  color: #777;
}

.movieIntro .ads {
  width: 33%;
  height: 160px;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 700;
}

.movieIntro .ads img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
}

.movieTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 170%;
  color: #ffffff;
}

.playerWrapper {
  margin: auto;
  margin-bottom: 40px;
}

.description {
  margin-top: 30px;
  display: block;
  padding: 10px 0;
  padding-right: 50px;
  overflow: hidden;
  color: white;
  position: relative;
  text-overflow: ellipsis;
  transition: height 0.3s ease;
}

.description p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.description div {
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 600;
  content: "ochish";
}

.description div:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1110px) {
  .container {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    width: 100%;
    margin-bottom: 60px;
  }

  .movieWrapper {
    padding-top: 40px;
    width: 100%;
  }

  .moviePicture img {
    width: 200px;
    height: 280px;
  }

  .movieTitle h3 {
    font-size: 32px;
  }

  .movieInfo {
    width: 60%;
    padding-top: 5px;
  }

  .movieInfo .moviData .info_text {
    margin-bottom: 0px;
    font-size: 15px;
  }

  .playerWrapper {
    max-width: 1024px;
    width: 100%;
  }
}

@media only screen and (max-width: 970px) {
  .container {
    max-width: 900px;
  }

  .moviePicture img {
    width: 190px;
    height: 260px;
  }

  .movieTitle h3 {
    font-size: 30px;
  }

  .playerWrapper {
    max-width: 900px;
    width: 100%;
  }
}

@media only screen and (max-width: 840px) {
  .container {
    display: flex;
    flex-direction: column;
    max-width: 760px;
    width: 100%;
  }

  .moviePicture img {
    width: 100%;
    height: auto;
  }

  .movieInfo {
    width: 60%;
    padding-top: 5px;
  }

  .movieInfo .moviData .info_text {
    margin-bottom: 0px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }

  .movieInfo .moviData span {
    font-size: 14px;
  }

  .playerWrapper {
    max-width: 760px;
    width: 100%;
  }

  .movieTitle {
    font-size: 15px;
  }
}

@media only screen and (max-width: 760px) {
  .container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
  }

  .movieIntro {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px !important;
    padding-left: 5px;
  }

  .filmBio {
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin-top: 20px;

    display: flex;
  }
  .movieInfo .moviData {
    flex-direction: column;
  }
  .movieInfo .moviData .info_text {
    font-size: 16px;
  }

  .moviePicture {
    width: 40%;
  }
  .movieInfo {
    width: 60%;
    margin: 0;
    justify-content: flex-end;
  }
  .movieInfo .moviData span {
    font-size: 18px;
  }
  .genre {
    margin-top: 10px;
  }

  .movieIntro .ads {
    width: 100%;
    margin-top: 30px;
    height: 160px;
  }

  .description p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 460px) {
  .container {
    width: 100%;
    padding-bottom: 100px;
  }

  .moviData > span {
    font-size: 16px;
  }

  .description {
    padding: 0 15px;
  }

  .movieIntro {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .filmBio {
    width: 100%;
    padding-bottom: 100px;
    margin-top: 20px;
    display: flex;
    margin-right: auto;
  }

  .moviePicture .film_img {
    position: relative;
  }

  .moviePicture .film_img img {
    width: 100%;
    height: auto;
  }

  .moviePicture .film_img .img_rate {
    display: block;
    text-align: center;
    position: absolute;
    bottom: -100px;
    width: 100%;
    background: #d7141d;
    opacity: 0.7;
    padding: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;
  }

  .moviePicture .film_img .img_rate p {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }

  .moviePicture .film_img .img_rate img {
    width: 86.8px;
    height: 14px;
  }

  .movieInfo .moviData .info_text {
    font-size: 14px;
  }

  .movieInfo .moviData span {
    font-size: 15px;
  }

  .movieWrapper {
    padding-top: 30px;
  }

  .movieIntro .ads {
    width: 100%;
    height: 160px;
    margin: 0;
  }

  .movieInfo .moviData {
    display: flex;
    flex-direction: column;
  }

  .movieInfo .moviData .info_text {
    color: #c6a1a1;
  }

  .genre {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0px;
  }

  .movieInfo .rating_film {
    display: none;
  }

  .playerWrapper {
    max-width: 380px;
    width: 100%;
  }
}
