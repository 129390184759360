.container * {
  font-family: "Lato", sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  text-decoration: none;
  color: white;
  position: relative;
  min-width: 170px;
}
.single_link{
  margin: 30px 10px;
  max-width: 210px;
}
.title {
  position: relative;
  min-height: 25px;
  min-width: 60%;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-right: auto;
  line-height: 22px;
}

.animate {
  background-color: rgba(37, 37, 37, 0.61);
  overflow: hidden;
  border-radius: 6px;
}
.animate::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.637),
    rgba(104, 104, 104, 0.808)
  );
  background-size: 400% 400%;
  /* background-color: blue; */
  width: 100%;
  animation: loading 1s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.description {
  composes: title;
  min-width: 80%;
  max-width: 100%;
  margin-top: 5px;
  min-height: 14px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.imgBox {
  display: flex;
  position: relative;
  width: 210px;
  height: 300px;
}
.imgBox img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.imgBox .hoverInfo {
  overflow: hidden;
  padding: 10px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  opacity: 0;
  background: rgba(137, 35, 40, 0.6);
  position: absolute;
  top: 0;
  z-index: 99;
  left: 0;
  transition: all 0.3s ease;
}
.hoverInfo .addInfoHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #c6a1a1;
}
.hoverInfo .addInfoBody {
  margin: 4px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #ffffff;
}
.container:hover .hoverInfo {
  opacity: 1;
}
.container .hoverInfo .star {
  display: flex;
  margin-bottom: auto;
  margin-left: auto;
}
.container:hover .hoverInfo > .star {
  animation: entry-star 0.4s ease-in-out;
}
@keyframes entry-star {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  70% {
    transform: translateX(-4px);
  }
  100% {
    opacity: 1;

    transform: translateX(0);
  }
}

.container:hover .hoverInfo > .addInfoBody,
.container:hover .hoverInfo > .addInfoHeader {
  animation: fadeInDown 0.6s ease-in;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(30px) rotateX(60deg);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media only screen and (max-width: 830px) {
 
}

@media only screen and (max-width: 560px) {
  .container {
    min-width: auto;
  }
  .title {
    font-size: 18px;
  }

  .description {
    font-size: 13px;
  }

  .imgBox {
    height: 170px;
  }
}

@media only screen and (max-width: 420px) {
  .title {
    font-size: 14px;
  }

  .description {
    font-size: 10px;
  }

  .imgBox {
    height: 246px;
    max-width: 100%;
    width: 100%;
  }
  .single_link{
    max-width: 157px;
  }
}
