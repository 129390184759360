.container{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #000;
}
.box{
    display: flex;
    flex-direction: column;
}
.box img{
    display: flex;
    margin: auto;
    margin-bottom: 10px;
}

.box .text{
    display: flex;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 39px;
    text-align: center;
    color: #FFFFFF;
}