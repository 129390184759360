.description {
  composes: description from "../../movie/movieInfo/movieInfo.module.css";
}

.description p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.description div {
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 600;
  content: "ochish";
}

.playerArea {
  /* min-height: 90vh; */
  max-height: 90vh;
  overflow: hidden;
  border-radius: 10px;
}

.description div:hover {
  cursor: pointer;
}

.container .video{
  display: flex;
  max-width: 100%;
  min-height: 90vh;
  max-height: 90vh;
  height: 100%;
}

.video::-webkit-media-controls-panel{
  width: 100%;
}


.btn{
  display: flex;
  width: fit-content;
  padding: 10px;
  border: none;
  outline: none;
  background: #D7141D;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  transition: all .1s ease-in-out;
}
.btn:hover{
  background: #e72e37;
  cursor: pointer;
}
.btn:active{
  background: #9c080f;
}



/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 340px;
  margin-top: 85px;
  border-radius: 9px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
