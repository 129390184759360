
.seasonList{
    list-style: none;
    margin: 0;
    display: flex;
    padding: 0 120px;
}
.liElem{
    color: #fff;
    margin-right: 30px;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
    transition: all .3s;
}
.liElem.active{
    border-bottom: 1px solid red;
    color: red;
}
.serActive{
    color: darkred;
}
.seriesList{
    padding: 0 120px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.seriesBlock{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.seriesItem{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 20px;
    cursor: pointer;
    transition: all .3s;
}
.seriesItemActive{
    transform: scale(1.1);
}
.seriesImg{
    width: 100px;
    object-fit: contain;
    border-radius: 5px;
    transition: all .3s;
}
.seriesImgActive{
    border: 1px solid darkred;
}
.seriesTxt{
    color: #FFFFFF;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    transition: all .3s;
}
.seriesTxtActive{
    color: darkred;
}
