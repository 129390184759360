.container {
  composes: container from "../../../pages/app/app.css";
}


.category_wrapper * {
  font-family: "Lato", sans-serif;
}
.category_wrapper .swiper-slide {
  min-width: 200px;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  color: white;
  padding-top: 20px;
  padding-bottom: 15px;
}

.titleBox > h1 {
  font-weight: 800;
  line-height: 33px;
  font-size: 21px;
}

.title {
  display: flex;
  padding: 0px 0px;
  /* font-family: LatoRegular; */
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #d7141d;
  text-decoration: none;
}

.title:hover {
  text-decoration: underline;
}

.items {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}


.bottom {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;

  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 628.55%, rgba(255, 255, 255, 0.1) 1171.74%), #2A2A2B; */
  /* box-shadow: 0px -12px 30px -10px rgba(0, 0, 0, 0.12); */
}

.pagination {
  display: flex;
  padding: 5px 0;
  justify-content: center;
  width: 100%;
}
:root {
  --border: 1.5px;
}
.pagination .box.active::after {
  position: absolute;
  content: "";
  width: 120%;
  height: 120%;
  border-radius: 50%;
  border: var(--border) solid #9494955b;
  transform: rotate(-45deg);
  border-top: var(--border) solid #2a2a2b;
}
.pagination .box:hover {
  cursor: pointer;
  background-color: #9494952f;
}



@media only screen and (max-width: 1110px) {
  .container {
    composes: container from "../../../pages/app/app.css";
  }
  .bottom {
    flex-direction: column;
    align-items: center;
  }
  .bottom .pagination {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 970px) {
  .container {
    composes: container from "../../../pages/app/app.css";
    max-width: 950px;
    width: 100%;
  }

  .titleBox > h1 {
    font-size: 16px !important;
  }

  .title {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 850px) {
  .container {
    composes: container from "../../../pages/app/app.css";
    padding: 0 70px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    composes: container from "../../../pages/app/app.css";
    max-width: 760px;
    width: 100%;
  }

  .titleBox > h1 {
    font-size: 14px !important;
  }

  .title {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    composes: container from "../../../pages/app/app.css";
    max-width: 400px;
    padding: 0;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 425px) {
  .container {
    composes: container from "../../../pages/app/app.css";
    max-width: 400px;
    padding: 0 40px !important;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 560px) {
  .items {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 425px) {
  .container {
    composes: container from "../../../pages/app/app.css";
    padding: 0 10px !important;
  }
}

@media only screen and (max-width: 375px) {
  .container {
    composes: container from "../../../pages/app/app.css";
    max-width: 350px;
    padding: 0 10px;
    padding-right: 5px;
  }

  /* .items {
    flex-direction: column;
  } */
}

@media only screen and (max-width: 320px) {
  .container {
    composes: container from "../../../pages/app/app.css";
    max-width: 320px;
    padding: 0 10px;
    margin: 0;
  }
}
