@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container {
    width: 100%;
    padding: 30px 180px;
}
.heading {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
    margin: 0;
}
.paymentBlock {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 40px;
}
.svgBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.paymentSvg {
    fill: #FFFFFF;
    height: 40px;
    object-fit: fill;
}
.typesDiv {
    margin: 15px 0;
}
.img {
    height: 40px;
    object-fit: contain;
}
.label {
    cursor: pointer;
}
.label div {
    padding: 10px;
    border-radius: 6px;
}
.checkbox {
    display: none;
}
.checkbox:checked+.label div{
    background-color: #D7141D;
}
.typeBlock {
    margin-right: 50px;
}
.cardBlock {
    padding: 35px 30px;
    background: #111112;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}
.details {
    margin-top: 50px;
}
.cardTitle {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #949494;
    margin-left: 15px;
}
.cardInput {
    padding: 10px 16px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 28px;
    border: none;
    outline: none;
    width: 330px;
    margin-top: 15px;
}
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}
.cardAction {
    width: 100%;
    text-align: right;
    margin-top: 25px;
}
.cardBtn {
    background: none;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #D7141D;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: right;
}
.cardBtn:hover {
    text-decoration: underline;
}
.balanceTxt {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
}
.summ {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-left: 10px;
}
.cardMessage {
    padding: 40px 0;
    margin-bottom: 25px;
    border-bottom: 1px solid #777777;
}

.errorCardMessage {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    color: #777777;
    margin: 0;
}

.addCardLink {
    color: #D7141D;
    margin-left: 2px;
    cursor: pointer;
}

.addCardLink:hover {
    text-decoration: underline;
}

.addCardModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBody {
    padding: 25px;
    background: #0C0C0D;
    border-radius: 8px;
}

.card {
    width: 350px;
    height: 184px;
    background: linear-gradient(217deg, rgba(101, 3, 3, 0.8), rgba(166, 17, 17, 0) 70.71%),
    linear-gradient(127deg, rgba(204, 16, 16, 0.8), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(201, 72, 72, 0.8), rgba(0,0,255,0) 70.71%);
    padding: 20px;
    border-radius: 12px;
    display: flex;
    align-items: flex-end;
}

.cardForm {
    width: 100%;
}

.inputDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.inputDiv:not(:last-child) {
    margin-bottom: 10px;
}

.inputSpan {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.25);
    position: absolute;
    left: 0;
    bottom: 0;
}

.inputSpan::after {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    transition: all .2s;
    background: #ff0000;
}

.cardNumberInput:focus + .inputSpan::after {
    width: 100%;
}

.cardNumberInput {
    width: 293px;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 26px;
    color: #fff;
    outline: none;
    border: none;
    background: transparent;
    padding: 0 4px;
}
.cardDateInput {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    width: 67px;
    outline: none;
    border: none;
    background: none;
    padding: 0 4px;
}

.cardDateInput:focus + .inputSpan::after {
    width: 100%;
}

.miniInputDiv {
    position: relative;
}
.submitBtn {
    width: 100%;
    height: 50px;
    background: #bb0000;
    color: #FFFFFF;
    outline: none;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
}
.cardDetails {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.cardNumber {
    color: #FFFFFF;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.19em;
}
.defaultCard {
    margin-bottom: 60px;
}
.innerImg {
    width: 50%;
}
.messageBlock {
    position: fixed;
    right: 70px;
    top: 20px;
    padding: 20px 45px;
    background-color: #3c7200;
    border-radius: 8px;
    transition: all .3s;
    transform: translateY(-100%);
    opacity: 0;
}
.messageText {
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.messageActive {
    transform: translateY(0);
    opacity: 1;
}
.errorBlock {
    background-color: #8c0000;
}
.submitDiv {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    padding: 40px;
    border-radius: 8px;
    background: #0C0C0D;
    flex-direction: column;
}
.smsText {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
}
.smsButton {
    outline: none;
    background: #bb0000;
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    border: none;
    border-radius: 16px;
    width: 200px;
    height: 45px;
    margin-top: 20px;
    cursor: pointer;
}

@media (max-width: 425px) {
    .container {
        padding: 30px 10px;
    }

    .paymentBlock {
        flex-direction: column;
    }

    .card {
        margin: 0 auto;
        width: 100%;
    }

    .img {
        width: 80%;
        height: auto;
        margin: 0 auto;
    }

    .svgBlock {
        width: 100%;
    }

    .typeBlock {
        margin: 0;
        display: none;
    }

    .paymentSvg {
        width: 49%;
        height: auto;
    }

    .imgDiv {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .heading {
        display: none;
    }

    .cardNumber {
        font-size: 22px;
    }

    .cardInput {
        width: 100%;
    }

    .cardBlock {
        width: 100%;
    }

}