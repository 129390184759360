.container {
  display: flex;
}
.container input::-webkit-inner-spin-button,
.container input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.container input {
  appearance: none;
  -webkit-appearance: none;
  margin: 0 10px;
  display: flex;
  width: 60px;
  font-family: LatoRegular;
  transition: border 0.4s ease-in-out;
  border: none;
  background: rgba(255, 255, 255, 0.06);
  padding: 10px 20px;
  font-size: 19px;
  align-items: center;
  justify-content: center;
  outline: none;
  font-size: 16px;
  line-height: 18px;
  color: white;
  border-radius: 28px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.container input:first-child {
  margin-left: 0;
}

.container input[type="number"]::-ms-reveal,
.container input[type="number"]::-ms-clear {
  display: none;
}

@media screen and (max-width: 1200px) {
  .container input {
    width: 50px;
    padding: 10px 12px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 760px) {
  .container input {
    margin: 0 10px;
    width: 60px;
    padding: 10 20px;
  }
}

@media screen and (max-width: 500px) {
  .container input {
    width: 50px;
    padding: 12px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 382px) {
  .container input {
    width: 40px;
    padding: 7px;
    margin: 0 5px;
  }
}
