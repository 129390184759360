@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  margin: 0;
  padding: 0;
  user-zoom: unset;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

body::-webkit-scrollbar {
   width: 10px;
 }

body::-webkit-scrollbar-track {
   background: #fff;
 }

body::-webkit-scrollbar-thumb {
   background: #FB0D18;
 }

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 120px;
}

@media only screen and (max-width: 1200px) {
  .container {
    max-width: 1200px !important;
    width: 100% !important;
    margin: 0 auto;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1110px) {
  .container {
    width: 100% !important;
    overflow: visible !important;
  }
}

@media only screen and (max-width: 970px) {
  .container {
    width: 100% !important;
    overflow: visible !important;
  }
}

@media only screen and (max-width: 850px) {
  .container {
    padding: 0 20px !important;
    margin: 0 auto !important;
  }
}

@media only screen and (max-width: 815px) {
  .container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    padding: 0 20px!important;
  }
}

@media only screen and (max-width: 425px) {
  .container {
    padding: 0 20px !important;
  }
}

@media only screen and (max-width: 375px) {
  .container {
    padding: 0 20px!important;
  }
}
@media screen and(max-width: 1120px) {
  .container {
    padding: 0 120px !important;
  }
}

@media only screen and (max-width: 320px) {
  .container {
    padding: 0 15px !important;
    margin: 0 !important;
  }
}


.swiper-container.swiper-container.swiper-container-initialized.swiper-container-horizontal.swiper-container-pointer-events.mySwiper {
  width: 100%;
  margin: 0 !important;
}

.triller_container__F7_m- .swiper-container.swiper-container.swiper-container-initialized.swiper-container-horizontal.swiper-container-pointer-events.mySwiper {
  overflow: visible;
  padding: 0 120px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.triller_container__F7_m- .swiper-container.swiper-container.swiper-container-initialized.swiper-container-horizontal.swiper-container-pointer-events.mySwiper .swiper-slide {
  opacity: 0.4 !important   ;
  -webkit-transform: scale(0.9) !important;
          transform: scale(0.9) !important;
}
.triller_container__F7_m- .swiper-container.swiper-container.swiper-container-initialized.swiper-container-horizontal.swiper-container-pointer-events.mySwiper .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important;
}

.swiper-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.swiper-button-prev {
  background-image: url(/static/media/arrow.512eed1d.svg) !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  left: 40% !important;
}

.swiper-button-next {
  background-image: url(/static/media/arrow.512eed1d.svg) !important;
  right: 40% !important;
}

.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  transition: all ease 0.5s;
  background: #383839;
}
.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
   transition: all ease 0.5s;
   background: #FF2E2E;
 }  

 #playerRef .swiper-pagination.swiper-pagination-bullets {
    bottom: 115px !important;
    padding: 8px;

    
 }
 #playerRef .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    background: #1B1B1B;
    opacity: 1;
    transition: all ease 0.5s;
 }
 #playerRef .swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    transition: all ease 0.5s;
    background: #FF2E2E;
 }



.swiper-button-prev,
.swiper-button-next {
  background-repeat: no-repeat !important;
  background-color: #1B1B1B;
  
  align-items: center;
  justify-content: center;
  background-position: center !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  top: calc(100vh - 130px) !important;
  z-index: 11 !important;
  transition: all ease 0.5s;
}



.swiper-button-prev:hover {
  background: #FF2E2E;
  cursor: pointer;
  transition: all ease 0.5s;
}
.swiper-button-next:hover {
  background: #FF2E2E;
  cursor: pointer;
  transition: all ease 0.5s;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "" !important;
}

#playerRef .swiper-container {
  width: 100%;
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 425px) {
  #playerRef .swiper-container{
    height: 80%;
  }
}

* {
  margin: 0;
  padding: 0;
  user-zoom: unset;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

body::-webkit-scrollbar {
   width: 10px;
 }

body::-webkit-scrollbar-track {
   background: #fff;
 }

body::-webkit-scrollbar-thumb {
   background: #FB0D18;
 }

.app_container__1VmGk {
  width: 100%;
  margin: 0 auto;
  padding: 0 120px;
}

@media only screen and (max-width: 1200px) {
  .app_container__1VmGk {
    max-width: 1200px !important;
    width: 100% !important;
    margin: 0 auto;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1110px) {
  .app_container__1VmGk {
    width: 100% !important;
    overflow: visible !important;
  }
}

@media only screen and (max-width: 970px) {
  .app_container__1VmGk {
    width: 100% !important;
    overflow: visible !important;
  }
}

@media only screen and (max-width: 850px) {
  .app_container__1VmGk {
    padding: 0 20px !important;
    margin: 0 auto !important;
  }
}

@media only screen and (max-width: 815px) {
  .app_container__1VmGk {
    width: 100% !important;
  }
}

@media only screen and (max-width: 550px) {
  .app_container__1VmGk {
    padding: 0 20px!important;
  }
}

@media only screen and (max-width: 425px) {
  .app_container__1VmGk {
    padding: 0 20px !important;
  }
}

@media only screen and (max-width: 375px) {
  .app_container__1VmGk {
    padding: 0 20px!important;
  }
}
@media screen and(max-width: 1120px) {
  .app_container__1VmGk {
    padding: 0 120px !important;
  }
}

@media only screen and (max-width: 320px) {
  .app_container__1VmGk {
    padding: 0 15px !important;
    margin: 0 !important;
  }
}


.app_swiper-container__3G3QX.app_swiper-container__3G3QX.app_swiper-container-initialized__2UjAb.app_swiper-container-horizontal__usjRA.app_swiper-container-pointer-events__39-fC.app_mySwiper__3M37r {
  width: 100%;
  margin: 0 !important;
}

.app_triller_container__F7_m-__1AV7l .app_swiper-container__3G3QX.app_swiper-container__3G3QX.app_swiper-container-initialized__2UjAb.app_swiper-container-horizontal__usjRA.app_swiper-container-pointer-events__39-fC.app_mySwiper__3M37r {
  overflow: visible;
  padding: 0 120px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.app_triller_container__F7_m-__1AV7l .app_swiper-container__3G3QX.app_swiper-container__3G3QX.app_swiper-container-initialized__2UjAb.app_swiper-container-horizontal__usjRA.app_swiper-container-pointer-events__39-fC.app_mySwiper__3M37r .app_swiper-slide__1kJJF {
  opacity: 0.4 !important   ;
  -webkit-transform: scale(0.9) !important;
          transform: scale(0.9) !important;
}
.app_triller_container__F7_m-__1AV7l .app_swiper-container__3G3QX.app_swiper-container__3G3QX.app_swiper-container-initialized__2UjAb.app_swiper-container-horizontal__usjRA.app_swiper-container-pointer-events__39-fC.app_mySwiper__3M37r .app_swiper-slide__1kJJF.app_swiper-slide-active__3-QFl {
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important;
}

.app_swiper-wrapper__MmmJ3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.app_swiper-button-prev__3INA9 {
  background-image: url(/static/media/arrow.512eed1d.svg) !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  left: 40% !important;
}

.app_swiper-button-next__1aiwc {
  background-image: url(/static/media/arrow.512eed1d.svg) !important;
  right: 40% !important;
}

.app_swiper-pagination__38Ouh.app_swiper-pagination-bullets__3BncW .app_swiper-pagination-bullet__2fznw {
  width: 12px;
  height: 12px;
  transition: all ease 0.5s;
  background: #383839;
}
.app_swiper-pagination__38Ouh.app_swiper-pagination-bullets__3BncW .app_swiper-pagination-bullet__2fznw.app_swiper-pagination-bullet-active__HjZy_ {
   transition: all ease 0.5s;
   background: #FF2E2E;
 }  

 #app_playerRef__215r- .app_swiper-pagination__38Ouh.app_swiper-pagination-bullets__3BncW {
    bottom: 115px !important;
    padding: 8px;

    
 }
 #app_playerRef__215r- .app_swiper-pagination__38Ouh.app_swiper-pagination-bullets__3BncW .app_swiper-pagination-bullet__2fznw {
    background: #1B1B1B;
    opacity: 1;
    transition: all ease 0.5s;
 }
 #app_playerRef__215r- .app_swiper-pagination__38Ouh.app_swiper-pagination-bullets__3BncW .app_swiper-pagination-bullet__2fznw.app_swiper-pagination-bullet-active__HjZy_ {
    transition: all ease 0.5s;
    background: #FF2E2E;
 }



.app_swiper-button-prev__3INA9,
.app_swiper-button-next__1aiwc {
  background-repeat: no-repeat !important;
  background-color: #1B1B1B;
  
  align-items: center;
  justify-content: center;
  background-position: center !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  top: calc(100vh - 130px) !important;
  z-index: 11 !important;
  transition: all ease 0.5s;
}



.app_swiper-button-prev__3INA9:hover {
  background: #FF2E2E;
  cursor: pointer;
  transition: all ease 0.5s;
}
.app_swiper-button-next__1aiwc:hover {
  background: #FF2E2E;
  cursor: pointer;
  transition: all ease 0.5s;
}

.app_swiper-button-next__1aiwc::after,
.app_swiper-button-prev__3INA9::after {
  content: "" !important;
}

#app_playerRef__215r- .app_swiper-container__3G3QX {
  width: 100%;
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 425px) {
  #app_playerRef__215r- .app_swiper-container__3G3QX{
    height: 80%;
  }
}

.footer_container__2ekqb {
}
.footer_footer__3Gm3_ * {
  font-family: "Lato", sans-serif;
}

.footer_footer_footer__2A8C7 * {
  font-family: "Lato", sans-serif;
}

.footer_footer_main__160MM {
  background-color: #680000;
  padding: 30px;
}

.footer_footer_body__2Fypd {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer_download_title__2KDPu {
  margin-bottom: 20px;
  color: #fff;
  font-size: 16px;
}
.footer_download_link__3WdA9 img {
  width: 110px;
  height: 40px;
  margin: 5px;
}

.footer_wrapper_footer__3j339 {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.footer_categories__265mK {
  list-style-type: none;
  margin: 0 5px;
}

.footer_categories__265mK .footer_category_item__3YX-4 {
  padding: 0px 10px 10px 0px;
}

.footer_categories__265mK.footer_dark__2sE5s .footer_category_item__3YX-4 a {
  color: #000;
  text-decoration: none;
}

.footer_categories__265mK .footer_category_item__3YX-4 a {
  color: #fff;
  text-decoration: none;
}

.footer_categories__265mK .footer_category_item__3YX-4 a:hover {
  color: #555;
  text-decoration: none;
}

.footer_info_liks__orX5d {
  padding: 0;
  list-style-type: none;
  margin: 0 5px;
}

.footer_info_liks__orX5d .footer_info_link__item__2Xu67 {
  padding: 0 10px 10px 0;
}

.footer_info_liks__orX5d .footer_info_link__item__2Xu67 a {
  color: #fff;
  text-decoration: none;
}

.footer_boxBottom1__1X2Ns {
  display: none;
}

.footer_info_liks__orX5d.footer_dark__2sE5s .footer_info_link__item__2Xu67 a {
  color: #000;
  text-decoration: none;
}

.footer_info_liks__orX5d .footer_info_link__item__2Xu67 a:hover {
  color: #555;
  text-decoration: none;
}

.footer_info_liks__orX5d .footer_info_link__item__2Xu67 .footer_disabled__4vTQB {
  color: #777;
}

.footer_support_links__2Aqvc {
  list-style-type: none;
  padding: 0;
  margin: 0 5px;
}

.footer_support_links__2Aqvc p {
  color: #777;
  margin: 5px;
  margin-bottom: 15px;
}

.footer_support_links__2Aqvc .footer_leave_comment__1d6ts {
  margin-top: 15px;
}

.footer_support_links__2Aqvc .footer_support_link__item__179Yd {
  padding: 0 10px 10px 0px;
  padding-left: 0;
}

.footer_support_links__2Aqvc .footer_support_link__item__179Yd a {
  color: #fff;
  text-decoration: none;
}

.footer_support_links__2Aqvc.footer_dark__2sE5s .footer_support_link__item__179Yd a {
  color: #000;
  text-decoration: none;
}

.footer_support_links__2Aqvc .footer_support_link__item__179Yd a:hover {
  color: #555;
  text-decoration: none;
}

.footer_footer_footer__2A8C7 {
  background: black;
  padding: 10px;
}

.footer_social_links_and_author__2Iygr {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.footer_social_links__2iesF {
  display: flex;
  list-style-type: none;
}

.footer_social_links__2iesF .footer_link_item__mg9c3 a {
  margin-right: 20px;

}

.footer_author__2z7GM {
  display: flex;
  /* font-family: LatoRegular; */
  font-weight: 500;
  font-size: 12px;
  line-height: 29px;
  color: #ffffff;
}
.footer_author__2z7GM.footer_dark__2sE5s {
  color: #000;
}
.footer_app_downloading__1SQZo .footer_boxBottom__9rPi5 {
  margin-top: 10px;
}

.footer_social_links__2iesF {
  display: flex;
  align-items: center;
}

.footer_social_links__2iesF .footer_link_item__mg9c3 img{
  width: 30px;
  height: 30px;
}

.footer_social_links__2iesF .footer_link_item__mg9c3 .footer_istagram__117gA{
  width: 35px;
  height: 35px;
}

.footer_socialTitle__22PlL {
  padding: 20px 0;
  margin-bottom: 0px;
  color: white;
}

/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */

@media only screen and (max-width: 1110px) {
  .footer_container__2ekqb {
    max-width: 1024px;
    width: 100%;
  }

  .footer_download_title__2KDPu {
    font-size: 16px;
  }
  .footer_download_link__3WdA9 img {
    width: 110px;
    height: 40px;
    margin: 5px;
  }

  .footer_categories__265mK .footer_category_item__3YX-4 a,
  .footer_info_liks__orX5d .footer_info_link__item__2Xu67 a,
  .footer_support_links__2Aqvc p,
  .footer_support_links__2Aqvc .footer_support_link__item__179Yd a {
    font-size: 14px;
  }

  .footer_social_links__2iesF .footer_link_item__mg9c3 a {
    font-size: 14px;
  }

  .footer_author__2z7GM {
    font-size: 22px;
  }
}

/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */

@media only screen and (max-width: 970px) {
  .footer_container__2ekqb {
    max-width: 900px;
    width: 100%;
  }
}

/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */
/* ---------------------------------------------------------------------------------  */

@media only screen and (max-width: 768px) {
  .footer_container__2ekqb {
    max-width: 760px;
    width: 100%;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .footer_wrapper_footer__3j339 {
    margin: 10px 0;
    justify-content: space-between;
  }

  .footer_download_title__2KDPu {
    font-size: 16px;
  }

  .footer_download_link__3WdA9 img {
    width: 80px;
    height: 30px;
  }

  .footer_categories__265mK .footer_category_item__3YX-4 a,
  .footer_info_liks__orX5d .footer_info_link__item__2Xu67 a,
  .footer_support_links__2Aqvc p,
  .footer_support_links__2Aqvc .footer_support_link__item__179Yd a {
    font-size: 12px;
  }

  .footer_footer_footer__2A8C7 {
    padding: 20px;
  }

  .footer_social_links__2iesF .footer_link_item__mg9c3 a {
    font-size: 12px;
  }

  .footer_social_links__2iesF .footer_link_item__mg9c3 a img {
    width: 25px;
    height: 25px;
  }

  .footer_author__2z7GM {
    font-size: 20px;
  }
}

@media only screen and (max-width: 760px) {
  .footer_container__2ekqb {
    max-width: 650px;
    width: 100%;
  }
  .footer_footer_body__2Fypd {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer_wrapper_footer__3j339 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 488px) {
  .footer_container__2ekqb {
    max-width: 430px;
  }
  .footer_social_links_and_author__2Iygr {
    display: flex;
    flex-direction: column;
  }

  .footer_categories__265mK li a,
  .footer_info_liks__orX5d .footer_info_link__item__2Xu67 a .footer_support_links__2Aqvc {
    font-size: 10px;
  }
  .footer_support_links__2Aqvc {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 652px) {
  .footer_container__2ekqb {
    padding: 0 70px;
  }
}

@media only screen and (max-width: 425px) {
  .footer_footer_main__160MM {
    background-color: #680000;
    padding: 0 30px;
  }

  .footer_download_title__2KDPu {
    font-size: 16px;
  }

  .footer_boxBottom__9rPi5 {
    display: none;
  }

  .footer_downloads__SHPHG {
    width: 100%;
  }

  .footer_downloads__SHPHG a img {
    width: 120px;
    height: 40px;
    margin-left: 10px;
  }

  .footer_downloads__SHPHG a img:first-child {
    margin-left: 0;
  }

  .footer_categories__265mK .footer_category_item__3YX-4 a {
    font-size: 16px;
  }

  .footer_info_liks__orX5d .footer_info_link__item__2Xu67 a {
    font-size: 16px;
  }

  .footer_support_links__2Aqvc {
    margin-top: 20px;
  }

  .footer_support_links__2Aqvc p {
    font-size: 16px;
    margin-left: 0;
  }

  .footer_support_links__2Aqvc {
    width: 100%;
  }

  .footer_support_link__item_media__3SyKd {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .footer_support_links__2Aqvc .footer_support_link__item__179Yd a {
    font-size: 16px;
  }

  .footer_author__2z7GM {
    font-size: 16px;
  }
}

@media only screen and (max-width: 375px) {
  .footer_support_link__item_media__3SyKd p {
    font-size: 14px;
  }

  .footer_support_link__item_media__3SyKd .footer_support_link__item__179Yd a {
    font-style: 14px;
  }

  .footer_boxBottom1__1X2Ns .footer_socialTitle__22PlL {
    font-size: 14px;
  }
}

@media only screen and (max-width: 320px) {
  .footer_container__2ekqb {
    padding: 0;
  }

  .footer_footer_main__160MM {
    background-color: #680000;
    padding: 0px;
  }

  .footer_download_title__2KDPu {
    font-size: 16px;
  }

  .footer_downloads__SHPHG {
    width: 100%;
  }

  .footer_downloads__SHPHG a img {
    width: 120px;
    height: 40px;
    margin-left: 10px;
  }

  .footer_downloads__SHPHG a img:first-child {
    margin-left: 0;
  }

  .footer_categories__265mK .footer_category_item__3YX-4 a {
    font-size: 16px;
  }

  .footer_info_liks__orX5d .footer_info_link__item__2Xu67 a {
    font-size: 16px;
  }

  .footer_support_links__2Aqvc {
    margin-top: 20px;
  }

  .footer_support_links__2Aqvc .footer_support_link__item__179Yd a {
    font-size: 16px;
  }

  .footer_author__2z7GM {
    font-size: 16px;
  }
}

.ads_container__KWCM1 {
  width: 100%;
}

.ads_container__KWCM1 * {
  font-family: "Lato", sans-serif;
}

.ads_ads__3XDjg {
  margin-bottom: 20px;
}

.ads_ads__3XDjg a {
  width: 100%;
  display: flex;
  padding: 20px 0;
}

.ads_ads__3XDjg a img {
  width: 100%;
  height: 100px;
}

@media only screen and (max-width: 1110px) {
  .ads_container__KWCM1 {
    width: 100%;
  }
}

@media only screen and (max-width: 970px) {
  .ads_container__KWCM1 {
    max-width: 950px;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .ads_container__KWCM1 {
    max-width: 890px;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .ads_container__KWCM1 {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .ads_container__KWCM1 {
    padding: 0 15px !important;
  }
  .ads_ads__3XDjg {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 375px) {
  .ads_container__KWCM1 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 320px) {
  .ads_container__KWCM1 {
    padding: 0 15px;
  }
}

.navbar_container__1OS2p {
}

.navbar_nav__2WMPS * {
  font-family: "Lato", sans-serif;
}


.navbar_burger__26juv {
  position: relative;
  width: 34px;
  height: 34px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
}
.navbar_sub_li_elem__2nNrQ{
  position: relative;
}
.navbar_sub_menu__2YeVM{
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
  padding: 15px 20px 0;
  list-style: none;
  z-index: 1060;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  width: 250%;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  border-radius: 5px;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  transition: all .2s;
}
.navbar_sub_menu__2YeVM:hover{
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
.navbar_sub_menu_item__3aMZj{
  margin-bottom: 15px;
}
.navbar_nav_menu__1hKNU{
  color: #bbbbbb;
  padding: 0 15px;
  font-size: 12.55px;
  font-weight: 500;
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  line-height: 17px;
  cursor: pointer;
}
.navbar_sub_menu_links__1aw0w{
  padding: 0!important;
  width: 100%!important;
  transition: all .3s;
}
.navbar_sub_menu_links__1aw0w:hover{
  color: darkred!important;
}
.navbar_sub_li_elem__2nNrQ:hover .navbar_sub_menu__2YeVM{
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
@media only screen and (max-width: 1110px) {
  .navbar_burger__26juv {
    display: flex;
    
  }
}

.navbar_burger__26juv span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 4px;
  position: relative;
  background: #FB0D18;
  border-radius: 50px;
}
.navbar_burger__26juv span::after {
  content: "";
  position: absolute;
  width: 28px;
  height: 4px;
  background: #FB0D18;
  -webkit-transform: translateY(8px);
          transform: translateY(8px);
  border-radius: 50px;
  transition: all ease 0.5s;
  
}
.navbar_burger__26juv span::before {
  content: "";
  position: absolute;
  width: 28px;
  height: 4px;
  background: #FB0D18;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
  border-radius: 50px;
  transition: all ease 0.5s;
}
.navbar_burger_active__2mZn5 {
  position: relative;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
   margin-right: 20px;
}

@media only screen and (max-width: 1110px) {
  .navbar_burger_active__2mZn5 {
    display: flex;
  }
}


.navbar_burger_active__2mZn5 span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 0;
  position: relative;
  background: #FB0D18;
  border-radius: 50px;
}
.navbar_burger_active__2mZn5 span::after {
  content: "";
  position: absolute;
  width: 28px;
  height: 4px;
  background: #FB0D18;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-radius: 50px;
  transition: all ease 0.5s;
  
}
.navbar_burger_active__2mZn5 span::before {
  content: "";
  position: absolute;
  width: 28px;
  height: 4px;
  background: #FB0D18;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 50px;
  transition: all ease 0.5s;
}




.navbar_nav__2WMPS {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.navbar_nav__2WMPS .navbar_logo_link__PzRch img {
  padding: 0;
}

.navbar_menu_body__3xxI8 {
  display: none;
}

.navbar_nav__2WMPS .navbar_logo_link__PzRch {
  /* width: 155px; */
  height: 66px;
}

.navbar_navbar__3T20H {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 {
  list-style-type: none;
  width: -webkit-max-content;
  width: max-content;
}

.navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 a {
  padding: 0 15px;
  text-decoration: none;
  font-weight: 500;
  font-size: 12.55px;
  line-height: 17px;
  text-transform: uppercase;
  color: #bbbbbb;
}

.navbar_miniMenu__3F5oj .navbar_navbar__3T20H {
  display: none;
}

.navbar_user_tools__1wfwg {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navbar_search_tool__33chE {
  transition: width 0.3s ease;
  position: relative;
  width: 100%;
  display: flex;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #3c3c3e;
  border-radius: 16px;
  overflow: hidden;
  /* border: 0.3px solid #545456; */
  font-size: 18px;

  cursor: pointer;
}

.navbar_search_icon__2t3yO {
  width: 30px;
  height: 30px;
  transition: background 0.4s ease-in-out;
  border-radius: 50%;
  padding: 4px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar_search_icon__2t3yO img{
  width: 30px;
  height: 30px;
  transition: background 0.4s ease-in-out;
  border-radius: 50%;
  padding: 4px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
}
.navbar_search_icon__2t3yO:hover {
  background-color: #8585853f;
}
.navbar_search_tool__33chE input {
  transition: all ease 0.5s;
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;

  background-color: inherit;
  padding: 6px;
  border: none;
  outline: none;
}

.navbar_language__3lsNA {
  display: flex;
  padding: 0 10px;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  color: #fff;
}

.navbar_language__3lsNA.navbar_dark__3i8Ob a {
  color: #fff;
}

.navbar_language__3lsNA > a {
  padding: 0 5px;
  text-decoration: none;
  color: #000;
}
.navbar_language__3lsNA > a:hover {
  cursor: pointer;
}
.navbar_language__3lsNA > a.navbar_active__3p8R4 {
  font-weight: 900;
}

.navbar_languageMiniDevice__2mWIt {
  display: none;
  padding: 0 10px;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  text-align: center;
  justify-content: center;
}

.navbar_resultContainer__1acfX {
  display: flex;
  flex-wrap: wrap;
}

.navbar_languageMiniDevice__2mWIt.navbar_dark__3i8Ob a {
  color: #fff;
  padding: 6px 5px;
}

.navbar_languageMiniDevice__2mWIt > a {
  text-decoration: none;
  color: #000;
  display: block;
}
.navbar_languageMiniDevice__2mWIt > a:hover {
  cursor: pointer;
}
.navbar_languageMiniDevice__2mWIt > a.navbar_active__3p8R4 {
  font-weight: 900;
}


.navbar_toggleIcon__1zJoQ {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar_miniMenu__3F5oj {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80%;
  right: 0px;
  z-index: 9;
  background-color: #e7e7e7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2c3e50;
}

.navbar_miniMenu__3F5oj .navbar_menuItem__3XTkc {
  color: black;
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  padding: 9px 15px;
  border: 1px solid #dcdcdc;
  position: relative;
}

.navbar_menuItem__3XTkc:hover {
  background-color: #adacacbd;
  cursor: pointer;
}

.navbar_miniMenu__3F5oj .navbar_menuItem__3XTkc:only-child {
  border: 1px solid black;
}
.navbar_menuItem__3XTkc > img {
  margin-right: 4px;
}
.navbar_menuItem__3XTkc > a {
  text-decoration: none;
  color: inherit;
}
.navbar_menuItem__3XTkc a {
  text-decoration: none;
}
.navbar_itemName__3LsaI {
  position: relative;
}

.navbar_menuItem__3XTkc:hover .navbar_itemChild__WR_rt {
  visibility: visible;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  opacity: 1;
}

.navbar_itemChild__WR_rt {
  opacity: 0;
  transition: all 0.3s ease;
  -webkit-transform: translate(20px, 0);
          transform: translate(20px, 0);
  z-index: 999;
  position: absolute;
  top: 100%;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  right: 0px;
  background-color: #e7e7e7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  min-width: 90%;
}
.navbar_childItem__1LRcI {
  display: flex;
  align-items: center;
  padding: 9px;
}
.navbar_childItem__1LRcI:hover,
.navbar_childItem__1LRcI.navbar_active__3p8R4 {
  cursor: pointer;
  background-color: #adacacbd;
}

.navbar_containerItems__2fGhS {
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
  min-height: 100%;
}



@media only screen and (max-width: 1110px) {
  .navbar_container__1OS2p {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
  }

  .navbar_nav__2WMPS .navbar_logo_link__PzRch,
  .navbar_nav__2WMPS .navbar_logo_link__PzRch img {
    width: 100px;
    height: 66px;
  }

  .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 a {
    padding: 0 7px;
    font-size: 9.85px;
  }

  .navbar_search_tool__33chE input {
    padding: 3px;
    font-size: 15px;
  }

  .navbar_language__3lsNA > a {
    font-size: 11px;
  }

  .navbar_navbar__3T20H {
    position: absolute;
    top: 66px;
    max-height: 0;
    overflow: hidden;
    transition: all ease 0.5s;
    width: 150px;
    flex-direction: column;
    background: #0C0C0D;
    z-index: 100000;
  }

  .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 {
    margin: 5px 0;
  }
}

@media only screen and (max-width: 970px) {
  /* .container {
    composes: container from "../../pages/app/app.css";
    max-width: 950px;
    width: 100%;
    overflow: visible;
  } */

  .navbar_nav__2WMPS .navbar_logo_link__PzRch,
  .navbar_nav__2WMPS .navbar_logo_link__PzRch img {
    width: 90px;
    height: 66px;
  }

  .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 a {
    padding: 0 9px;
    font-size: 9px;
  }
}

@media only screen and (max-width: 850px) {
  .navbar_container__1OS2p {
    padding: 0 20px !important;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 815px) {
  .navbar_container__1OS2p {
    max-width: 100%;
    width: 100%;
  }

  .navbar_nav__2WMPS .navbar_logo_link__PzRch,
  .navbar_nav__2WMPS .navbar_logo_link__PzRch img {
    width: 90px;
    height: 55px;
  }

  .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 a {
    padding: 0 7px;
    font-size: 8.1px;
  }

  .navbar_user_tools__1wfwg {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .navbar_nav__2WMPS {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  nav > .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783:not(.navbar_navbar_link_item__Xu783:nth-child(4)) {
    
  }
  .navbar_navbar__3T20H {
    top: 45px;
  }
  .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 a {
    font-size: 14px;
  }

  .navbar_miniMenu__3F5oj .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 {
    display: flex;
  }

  .navbar_miniMenu__3F5oj .navbar_navbar__3T20H {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar_miniMenu__3F5oj .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 {
    width: 100%;
    border: 1px solid #dcdcdc;
  }

  .navbar_miniMenu__3F5oj .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 a {
    padding: 12px 15px;
    color: inherit;
  }
  .navbar_miniMenu__3F5oj .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783:hover {
    background-color: #adacacbd;
  }
  .navbar_language__3lsNA {
    display: none;
  }

  .navbar_menu_body__3xxI8 {
    display: none;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -240px;
    background-color: #fff;
    background-color: #1c1c1d;
    z-index: 99;
  }

  .navbar_menu_items__2lBxe li {
    padding: 15px;
  }

  .navbar_menu_items__2lBxe li a {
    text-decoration: none;
    color: #fff;
  }

  .navbar_menu_body__3xxI8 .navbar_menu_items__2lBxe {
    list-style: none;
  }

  .navbar_func_button__1a25K li .navbar_userSettings__C4zLy {
    left: -100px;
    bottom: -70px;
  }

  .navbar_languageMiniDevice__2mWIt {
    display: flex;
  }
}

@media only screen and (max-width: 550px) {
  .navbar_container__1OS2p {
    max-width: 100% !important;
    padding: 0 20px;
  }


  .navbar_search_tool__33chE {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}

@media only screen and (max-width: 425px) {
  .navbar_container__1OS2p {
    max-width: 400px;
    padding: 0;
    padding-right: 5px;
  }

  .navbar_nav__2WMPS {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .navbar_logo_link__PzRch > img {
    padding: 10px;
  }
  .navbar_languageMiniDevice__2mWIt.navbar_dark__3i8Ob a{
    color: #000000;
  }
}

@media only screen and (max-width: 420px) {
  .navbar_container__1OS2p {
    max-width: 350px;
    padding: 0;
    padding-right: 5px;
  }

  nav > .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783:nth-child(4) a {
    font-size: 12px !important;
  }

  .navbar_toggleIcon__1zJoQ img {
    width: 30px;
  }

  .navbar_nav__2WMPS {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
}
@media screen and(max-width: 1120px) {
  .navbar_container__1OS2p {
    padding: 0 120px;
  }

  .navbar_nav__2WMPS .navbar_logo_link__PzRch,
  .navbar_nav__2WMPS .navbar_logo_link__PzRch img {
    width: 105px;
    height: 66px;
  }

  .navbar_navbar__3T20H .navbar_navbar_link_item__Xu783 a {
    padding: 0 10px;
    font-size: 11.85px;
  }

  .navbar_search_tool__33chE input {
    padding: 5px;
    font-size: 17px;
  }
}

@media only screen and (max-width: 336px) {
  .navbar_container__1OS2p {
    max-width: 320px;
    padding: 0 15px;
    margin: 0;
  }

  .navbar_nav__2WMPS {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .navbar_search_tool__33chE {
    width: 90px;
  }
}

.movieItem_container__3AjJC * {
  font-family: "Lato", sans-serif;
}

.movieItem_container__3AjJC {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  text-decoration: none;
  color: white;
  position: relative;
  min-width: 170px;
}
.movieItem_single_link__J489y{
  margin: 30px 10px;
  max-width: 210px;
}
.movieItem_title__1m487 {
  position: relative;
  min-height: 25px;
  min-width: 60%;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-right: auto;
  line-height: 22px;
}

.movieItem_animate__SVHif {
  background-color: rgba(37, 37, 37, 0.61);
  overflow: hidden;
  border-radius: 6px;
}
.movieItem_animate__SVHif::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.637),
    rgba(104, 104, 104, 0.808)
  );
  background-size: 400% 400%;
  /* background-color: blue; */
  width: 100%;
  -webkit-animation: movieItem_loading__3NU5U 1s ease-in-out infinite;
          animation: movieItem_loading__3NU5U 1s ease-in-out infinite;
}

@-webkit-keyframes movieItem_loading__3NU5U {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes movieItem_loading__3NU5U {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.movieItem_description__2Njkz {
  min-width: 80%;
  max-width: 100%;
  margin-top: 5px;
  min-height: 14px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.movieItem_imgBox__1MhUP {
  display: flex;
  position: relative;
  width: 210px;
  height: 300px;
}
.movieItem_imgBox__1MhUP img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.movieItem_imgBox__1MhUP .movieItem_hoverInfo__3kSsV {
  overflow: hidden;
  padding: 10px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  opacity: 0;
  background: rgba(137, 35, 40, 0.6);
  position: absolute;
  top: 0;
  z-index: 99;
  left: 0;
  transition: all 0.3s ease;
}
.movieItem_hoverInfo__3kSsV .movieItem_addInfoHeader__3Ht8l {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #c6a1a1;
}
.movieItem_hoverInfo__3kSsV .movieItem_addInfoBody__2w2f0 {
  margin: 4px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #ffffff;
}
.movieItem_container__3AjJC:hover .movieItem_hoverInfo__3kSsV {
  opacity: 1;
}
.movieItem_container__3AjJC .movieItem_hoverInfo__3kSsV .movieItem_star__1HYTU {
  display: flex;
  margin-bottom: auto;
  margin-left: auto;
}
.movieItem_container__3AjJC:hover .movieItem_hoverInfo__3kSsV > .movieItem_star__1HYTU {
  -webkit-animation: movieItem_entry-star__2_vIx 0.4s ease-in-out;
          animation: movieItem_entry-star__2_vIx 0.4s ease-in-out;
}
@-webkit-keyframes movieItem_entry-star__2_vIx {
  0% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);

            transform: translateX(0);
  }
}
@keyframes movieItem_entry-star__2_vIx {
  0% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);

            transform: translateX(0);
  }
}

.movieItem_container__3AjJC:hover .movieItem_hoverInfo__3kSsV > .movieItem_addInfoBody__2w2f0,
.movieItem_container__3AjJC:hover .movieItem_hoverInfo__3kSsV > .movieItem_addInfoHeader__3Ht8l {
  -webkit-animation: movieItem_fadeInDown__2UlSj 0.6s ease-in;
          animation: movieItem_fadeInDown__2UlSj 0.6s ease-in;
}

@-webkit-keyframes movieItem_fadeInDown__2UlSj {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px) rotateX(60deg);
            transform: translateY(30px) rotateX(60deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes movieItem_fadeInDown__2UlSj {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px) rotateX(60deg);
            transform: translateY(30px) rotateX(60deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@media only screen and (max-width: 830px) {
 
}

@media only screen and (max-width: 560px) {
  .movieItem_container__3AjJC {
    min-width: auto;
  }
  .movieItem_title__1m487 {
    font-size: 18px;
  }

  .movieItem_description__2Njkz {
    font-size: 13px;
  }

  .movieItem_imgBox__1MhUP {
    height: 170px;
  }
}

@media only screen and (max-width: 420px) {
  .movieItem_title__1m487 {
    font-size: 14px;
  }

  .movieItem_description__2Njkz {
    font-size: 10px;
  }

  .movieItem_imgBox__1MhUP {
    height: 246px;
    max-width: 100%;
    width: 100%;
  }
  .movieItem_single_link__J489y{
    max-width: 157px;
  }
}

.notFound_container__2-PPP{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #000;
}
.notFound_box__2uKdc{
    display: flex;
    flex-direction: column;
}
.notFound_box__2uKdc img{
    display: flex;
    margin: auto;
    margin-bottom: 10px;
}

.notFound_box__2uKdc .notFound_text__2ANWM{
    display: flex;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 39px;
    text-align: center;
    color: #FFFFFF;
}
.triller_container__2mp55 {
  overflow: hidden;
  height: calc(100vh - 66px);
  font-family: "Lato", sans-serif;
  background: #000000;
}

:root {
  --heightCarouselItem: 641px;
}

.triller_player__2zb-r {
  display: flex;
  width: 100%;
  margin: auto;
}
.triller_player__2zb-r .triller_carouselItem__1Wd-W {
  display: flex;
  height: auto;
  min-height: 100%;
  height: calc(100vh - 66px);
}

.triller_player__2zb-r .triller_carouselItem__1Wd-W {
  min-width: 100vw;
}

.triller_player__2zb-r .triller_carouselWay__jeZZf {
  display: flex;
  background-color: black;

  height: calc(100vh - 66px);
  max-width: 100%;
  position: absolute;
}
/*buttons*/
.triller_player__2zb-r .triller_buttons__3ZIu1 {
  z-index: 99;
  display: flex;
  justify-content: space-around;
  margin: auto 20px;
  padding: 5px;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
}

.triller_player__2zb-r .triller_buttons__3ZIu1 .triller_box__3y9U_ {
  margin: 0 3px;
  width: 10px;
  height: 10px;
  color: white;
  display: flex;
  background-color: #111112;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
}
.triller_player__2zb-r .triller_buttons__3ZIu1 .triller_box__3y9U_:hover {
  cursor: pointer;
  background-color: #9494952f;
}

.triller_player__2zb-r .triller_buttons__3ZIu1 .triller_box__3y9U_.triller_active__4gEzN {
  position: relative;
  background-color: #df3e0d;
}
:root {
  --border: 1.5px;
}

.triller_swiper-button-prev__2F2wb {
  
  background-image: url(/static/media/arrow.512eed1d.svg) !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.triller_swiper-button-next__3Ft76 {
  background-image: url(/static/media/arrow.512eed1d.svg) !important;
  background-repeat: no-repeat;
  right: 200px !important;

}

.triller_swiper-pagination__3v42f.triller_swiper-pagination-bullets__2iHrD .triller_swiper-pagination-bullet__3TkVt {
  width: 12px;
  height: 12px;
  transition: all ease 0.5s;
  background: #6e6e6e;
}

.triller_swiper-pagination__3v42f.triller_swiper-pagination-bullets__2iHrD .triller_swiper-pagination-bullet__3TkVt.triller_swiper-pagination-bullet-active__2xbkj {
  transition: all ease 0.5s;
  background: #FF2E2E;
}



.triller_swiper-button-prev__2F2wb,
.triller_swiper-button-next__3Ft76 {
  background-repeat: no-repeat;
  background-color: #111112;
  align-items: center;
  justify-content: center;
  background-position: center;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}

.triller_swiper-button-prev__2F2wb:hover {
  background: #FF2E2E;
  cursor: pointer;
}

.triller_swiper-button-next__3Ft76:hover {
  background: #FF2E2E;
  cursor: pointer;
}

.triller_swiper-button-next__3Ft76::after,
.triller_swiper-button-prev__2F2wb::after {
  content: "" !important;
}


.triller_controls__3X6Yx {
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 30px !important;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 99;
}

@media only screen and (max-width: 970px) {
  .triller_controls__3X6Yx {
    height: -webkit-max-content;
    height: max-content;
    bottom: 20px;
    z-index: 99;
  }

  .triller_carousel__LzbMG {
    background-color: red !important;
  }
}

@media only screen and (max-width: 445px) {
  .triller_controls__3X6Yx {
    bottom: 0!important;
    z-index: 99;
  }

  .triller_controls__3X6Yx > div:nth-child(2) {
    margin: auto 50px;
  }
}

.trillerItem_container__33HQj {
  position: relative;
  display: flex;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: 100% 100%;
    height: calc(100vh - 66px);
}

.trillerItem_bg_image__3DCCI {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover !important;
}

.trillerItem_box__3ezO1 {
  color: aliceblue;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  /* padding: 40px 0; */
}

.trillerItem_box__3ezO1 .trillerItem_info__3RUje {
  font-family: Montserrat;
  font-style: normal;
  flex-direction: column;
  display: flex;
  width: 30%;
  max-width: 30%;
  padding-right: 30px;
  flex: 1 1;
}
.trillerItem_info__3RUje .trillerItem_name__T4gcC {
  margin: 10px 0px;
  font-weight: 900;
  font-size: 40px;
  line-height: 49px;
}
.trillerItem_info__3RUje .trillerItem_country__2L5wT {
  margin: 10px 0px;

  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.trillerItem_info__3RUje .trillerItem_description__5HccW {
  margin: 10px 0px;

  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
}
.trillerItem_genre__2Jtrk {
  margin: 10px 0px;
  flex-wrap: wrap;
  display: flex;
}

.trillerItem_genreItem__3KkjF {
  margin: 3px;
  padding: 3px 10px;
  margin-right: 10px;
  background: #111112;
  /* font-family: LatoRegular; */
  border-radius: 25px;
  display: flex;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
}

.trillerItem_rating__12p4q {
  margin: 10px 0px;

  display: flex;
}

.trillerItem_rating__12p4q .trillerItem_title__2o0k_ {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.trillerItem_button__3Rry7 {
  margin: 10px 0px;
}

.trillerItem_box__3ezO1 .trillerItem_player__2S3tp {
  display: flex;
  padding: 40px;
  padding-right: 0;
  flex: 1 1;
}


@media only screen and (max-width: 970px) {
  .trillerItem_container__33HQj {
    height: auto;
  }

  .trillerItem_box__3ezO1 {
    flex-direction: column-reverse;
    margin-top: 30px;
  }
  .trillerItem_box__3ezO1 .trillerItem_info__3RUje {
    width: 100%;
    padding-right: 0px;
  }
  .trillerItem_name__T4gcC {
    justify-content: flex-start;
    text-align: left;
  }
  .trillerItem_box__3ezO1 .trillerItem_player__2S3tp {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0px;
    width: 100%;
  }
  .trillerItem_button__3Rry7 {
    margin: 0px auto;
    -webkit-transform: translateY(0px) !important;
            transform: translateY(0px) !important;
  }
}

@media screen and (max-width: 770px) {
  .trillerItem_container__33HQj {
    padding: 0 50px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje > h1.trillerItem_name__T4gcC {
    font-size: 30px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje > h6.trillerItem_description__5HccW {
    font-size: 15px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje .trillerItem_genre__2Jtrk .trillerItem_genreItem__3KkjF {
    font-size: 12px;
  }

  .trillerItem_button__3Rry7 {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
}

@media screen and (max-width: 510px) {
  .trillerItem_container__33HQj {
    padding: 0 30px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje > h1.trillerItem_name__T4gcC {
    font-size: 30px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje > h6.trillerItem_description__5HccW {
    font-size: 15px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje .trillerItem_genre__2Jtrk .trillerItem_genreItem__3KkjF {
    font-size: 12px;
  }
}

@media screen and (max-width: 425px) {
  .trillerItem_container__33HQj {
    padding: 0 30px;
    height: calc(100vh - 66px);
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje > h1.trillerItem_name__T4gcC {
    font-size: 30px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje > h6.trillerItem_counter__1h4mi {
    font-size: 18px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje > h6.trillerItem_description__5HccW {
    font-size: 18px;
  }

  .trillerItem_box__3ezO1 .trillerItem_info__3RUje .trillerItem_genre__2Jtrk .trillerItem_genreItem__3KkjF {
    font-size: 12px;
  }

  .trillerItem_button__3Rry7 > button {
    font-size: 18px;
  }
  .trillerItem_box__3ezO1 .trillerItem_info__3RUje{
    max-width: none;
  }
  .trillerItem_player__2S3tp{
    max-height: 189px;
  }
  .trillerItem_button__3Rry7{

  }
}

.button_btn__3UBF4{
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border: none;
    outline: none;
    background: #D7141D;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    transition: all .1s ease-in-out;
}
.button_btn__3UBF4:hover{
    background: #e72e37;
    cursor: pointer;
}
.button_btn__3UBF4:active{
    background: #9c080f;
}
.player_container__1MbKz{
    padding-right: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    position: relative;
    background-color: black;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(25, 23, 32, 0.726);
}

.player_playerButton__3i4AO{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.player_trller_video__1SKOC{
    object-fit: cover;
    max-width: 100vw;
}
.player_trller_video__1SKOC::-internal-media-controls-download-button {
    display:none;
}

.player_trller_video__1SKOC::-webkit-media-controls-enclosure {
    overflow:hidden;
}

.player_trller_video__1SKOC::-webkit-media-controls-panel {
    width: calc(100% + 30px); /* Adjust as needed */
}
@media (max-width: 425px) {
    .player_trller_video__1SKOC{
        max-height: 189px;
    }
    .player_container__1MbKz{
        max-height: 189px;
    }
}

.sliderCounter_buttons__1NO61 {
  z-index: 99;
  display: flex;
  justify-content: space-around;
  margin: auto 20px;
  padding: 5px;
  align-items: center;
  border-radius: 24px;
  background: #383839;
  border-radius: 24px;
}

.sliderCounter_buttons__1NO61 .sliderCounter_box__19MXY {
  margin: 0 3px;
  width: 10px;
  height: 10px;
  color: white;
  display: flex;
  /* background-color: #111112; */
  transition: all 0.3s ease;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
  background-color: #9494952f;
}

.sliderCounter_buttons__1NO61 .sliderCounter_box__19MXY:hover {
  cursor: pointer;
  background-color: #9494952f;
}

.sliderCounter_buttons__1NO61 .sliderCounter_box__19MXY.sliderCounter_active__26bpX {
  position: relative;
  background-color: #df3e0d;
}

:root {
  --border: 1.5px;
}

.sliderCounter_controls__jGcn5 {
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 1000px;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 99;
  /* justify-content: center; */
}

.sliderCounter_next__2p_Mf,
.sliderCounter_prev__2nP4E {
  -webkit-user-select: none;
          user-select: none;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  /* background: rgba(255, 255, 255, 0.8); */
  background: #d7141d;
  /* border-radius: 2px; */
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}
.sliderCounter_next__2p_Mf {
  margin-left: 2px;
}
.sliderCounter_next__2p_Mf:hover,
.sliderCounter_prev__2nP4E:hover {
  cursor: pointer;
}
.sliderCounter_next__2p_Mf svg path,
.sliderCounter_prev__2nP4E svg path {
  stroke: white;
}

.sliderCounter_minMaxBack__34tLw {
  background: #1b1b1b !important;
}
.sliderCounter_minMaxStroke__33BTt {
  stroke: white;
}

.sliderCounter_advTopPrev__18rSY {
  -webkit-transform: translateY(-170px) translateX(-130%);
          transform: translateY(-170px) translateX(-130%);
}
.sliderCounter_advTopNext__3KCQP {
  -webkit-transform: translateY(-170px) translateX(130%);
          transform: translateY(-170px) translateX(130%);
}

@media only screen and (max-width: 1026px) {
  .sliderCounter_next__2p_Mf,
  .sliderCounter_prev__2nP4E {
    margin-top: auto;
  }
  .sliderCounter_buttons__1NO61 {
    margin-top: auto;
    margin-bottom: auto;
  }
  .sliderCounter_advTopPrev__18rSY {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    margin-left: 30px;
  }
  .sliderCounter_advTopNext__3KCQP {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    margin-right: 30px;
  }
}

.category_container__1Kq2J {
}


.category_category_wrapper__2BtMi * {
  font-family: "Lato", sans-serif;
}
.category_category_wrapper__2BtMi .category_swiper-slide__101Gn {
  min-width: 200px;
}

.category_titleBox__1o2q7 {
  display: flex;
  justify-content: space-between;
  color: white;
  padding-top: 20px;
  padding-bottom: 15px;
}

.category_titleBox__1o2q7 > h1 {
  font-weight: 800;
  line-height: 33px;
  font-size: 21px;
}

.category_title__314lZ {
  display: flex;
  padding: 0px 0px;
  /* font-family: LatoRegular; */
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #d7141d;
  text-decoration: none;
}

.category_title__314lZ:hover {
  text-decoration: underline;
}

.category_items__3Pd-j {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}


.category_bottom__Jsayw {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;

  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0) 628.55%, rgba(255, 255, 255, 0.1) 1171.74%), #2A2A2B; */
  /* box-shadow: 0px -12px 30px -10px rgba(0, 0, 0, 0.12); */
}

.category_pagination__2hooL {
  display: flex;
  padding: 5px 0;
  justify-content: center;
  width: 100%;
}
:root {
  --border: 1.5px;
}
.category_pagination__2hooL .category_box__3nCpL.category_active__2ByBo::after {
  position: absolute;
  content: "";
  width: 120%;
  height: 120%;
  border-radius: 50%;
  border: 1.5px solid #9494955b;
  border: var(--border) solid #9494955b;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-top: 1.5px solid #2a2a2b;
  border-top: var(--border) solid #2a2a2b;
}
.category_pagination__2hooL .category_box__3nCpL:hover {
  cursor: pointer;
  background-color: #9494952f;
}



@media only screen and (max-width: 1110px) {
  .category_container__1Kq2J {
  }
  .category_bottom__Jsayw {
    flex-direction: column;
    align-items: center;
  }
  .category_bottom__Jsayw .category_pagination__2hooL {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 970px) {
  .category_container__1Kq2J {
    max-width: 950px;
    width: 100%;
  }

  .category_titleBox__1o2q7 > h1 {
    font-size: 16px !important;
  }

  .category_title__314lZ {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 850px) {
  .category_container__1Kq2J {
    padding: 0 70px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .category_container__1Kq2J {
    max-width: 760px;
    width: 100%;
  }

  .category_titleBox__1o2q7 > h1 {
    font-size: 14px !important;
  }

  .category_title__314lZ {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 550px) {
  .category_container__1Kq2J {
    max-width: 400px;
    padding: 0;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 425px) {
  .category_container__1Kq2J {
    max-width: 400px;
    padding: 0 40px !important;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 560px) {
  .category_items__3Pd-j {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 425px) {
  .category_container__1Kq2J {
    padding: 0 10px !important;
  }
}

@media only screen and (max-width: 375px) {
  .category_container__1Kq2J {
    max-width: 350px;
    padding: 0 10px;
    padding-right: 5px;
  }

  /* .items {
    flex-direction: column;
  } */
}

@media only screen and (max-width: 320px) {
  .category_container__1Kq2J {
    max-width: 320px;
    padding: 0 10px;
    margin: 0;
  }
}

.notFound_container__3w5My{
}
.notFound_text__1U_CD{
    color: white;
}
.movieItem_container__2SmRl * {
  font-family: "Lato", sans-serif;
}

.movieItem_container__2SmRl {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
  text-decoration: none;
  color: white;
  position: relative;
  min-width: 170px;
}
.movieItem_single_link__2KzcP{
  margin: 30px 10px;
  max-width: 210px;
}
.movieItem_title__1PgGO {
  position: relative;
  min-height: 25px;
  min-width: 60%;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-right: auto;
  line-height: 22px;
}

.movieItem_animate__2ipzm {
  background-color: rgba(37, 37, 37, 0.61);
  overflow: hidden;
  border-radius: 6px;
}
.movieItem_animate__2ipzm::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.637),
    rgba(104, 104, 104, 0.808)
  );
  background-size: 400% 400%;
  /* background-color: blue; */
  width: 100%;
  -webkit-animation: movieItem_loading__-kQYJ 1s ease-in-out infinite;
          animation: movieItem_loading__-kQYJ 1s ease-in-out infinite;
}

@-webkit-keyframes movieItem_loading__-kQYJ {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes movieItem_loading__-kQYJ {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.movieItem_description__3BMPK {
  min-width: 80%;
  max-width: 100%;
  margin-top: 5px;
  min-height: 14px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.movieItem_imgBox__3QwRO {
  display: flex;
  position: relative;
  width: 210px;
  height: 300px;
}
.movieItem_imgBox__3QwRO img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.movieItem_imgBox__3QwRO .movieItem_hoverInfo__q_Xfq {
  overflow: hidden;
  padding: 10px;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  opacity: 0;
  background: rgba(137, 35, 40, 0.6);
  position: absolute;
  top: 0;
  z-index: 99;
  left: 0;
  transition: all 0.3s ease;
}
.movieItem_hoverInfo__q_Xfq .movieItem_addInfoHeader__27TFB {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #c6a1a1;
}
.movieItem_hoverInfo__q_Xfq .movieItem_addInfoBody__2S3wi {
  margin: 4px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #ffffff;
}
.movieItem_container__2SmRl:hover .movieItem_hoverInfo__q_Xfq {
  opacity: 1;
}
.movieItem_container__2SmRl .movieItem_hoverInfo__q_Xfq .movieItem_star__3_xoZ {
  display: flex;
  margin-bottom: auto;
  margin-left: auto;
}
.movieItem_container__2SmRl:hover .movieItem_hoverInfo__q_Xfq > .movieItem_star__3_xoZ {
  -webkit-animation: movieItem_entry-star__3KHHD 0.4s ease-in-out;
          animation: movieItem_entry-star__3KHHD 0.4s ease-in-out;
}
@-webkit-keyframes movieItem_entry-star__3KHHD {
  0% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);

            transform: translateX(0);
  }
}
@keyframes movieItem_entry-star__3KHHD {
  0% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);

            transform: translateX(0);
  }
}

.movieItem_container__2SmRl:hover .movieItem_hoverInfo__q_Xfq > .movieItem_addInfoBody__2S3wi,
.movieItem_container__2SmRl:hover .movieItem_hoverInfo__q_Xfq > .movieItem_addInfoHeader__27TFB {
  -webkit-animation: movieItem_fadeInDown__LGyt4 0.6s ease-in;
          animation: movieItem_fadeInDown__LGyt4 0.6s ease-in;
}

@-webkit-keyframes movieItem_fadeInDown__LGyt4 {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px) rotateX(60deg);
            transform: translateY(30px) rotateX(60deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes movieItem_fadeInDown__LGyt4 {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px) rotateX(60deg);
            transform: translateY(30px) rotateX(60deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@media only screen and (max-width: 830px) {
 
}

@media only screen and (max-width: 560px) {
  .movieItem_container__2SmRl {
    min-width: auto;
  }
  .movieItem_title__1PgGO {
    font-size: 18px;
  }

  .movieItem_description__3BMPK {
    font-size: 13px;
  }

  .movieItem_imgBox__3QwRO {
    height: 170px;
  }
}

@media only screen and (max-width: 420px) {
  .movieItem_title__1PgGO {
    font-size: 14px;
  }

  .movieItem_description__3BMPK {
    font-size: 10px;
  }

  .movieItem_imgBox__3QwRO {
    height: 246px;
    max-width: 100%;
    width: 100%;
  }
  .movieItem_single_link__2KzcP{
    max-width: 157px;
  }
}

.header_container__Q5nEt {
}

.header_row_container__3p8gN * {
    font-family: 'Montserrat', sans-serif;
}

.header_row_container__3p8gN {
    display: flex;
    justify-content: space-between;
    padding: 60px 0 10px;
}

.header_title_category__2p2UA{
    display: flex;
    font-weight: bold;
    font-size: 32px;
    margin: 0;
    margin-bottom: 20px;
    line-height: 29px;
    align-items: center;
}
.header_favourites__1EC5d img:hover{
    cursor: pointer;
}


@media only screen and (max-width: 1110px) {
    .header_container__Q5nEt {
        /* max-width: 1024px; */
        width: 100%;
        /* padding: 0 15px; */
    }
}

@media only screen and (max-width: 970px) {
    .header_container__Q5nEt {
        max-width: 950px;
        width: 100%;
    }
}


@media (max-width:900px) {
    .header_container__Q5nEt {
        max-width: 890px;
        width: 100%;
    }
}


@media only screen and (max-width: 850px) {
    .header_container__Q5nEt {
        padding: 0 70px;
        margin: 0 auto;
    }
}


@media only screen and (max-width: 768px) {
    .header_container__Q5nEt {
        width: 100%;
    }
}


@media only screen and (max-width: 550px) {
    .header_container__Q5nEt {
        padding: 0 70px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 425px) {
    .header_container__Q5nEt {
        padding: 0 15px;
    }
    .header_title__3LD_Y{
        font-size: 18px;
    }
}


@media only screen and (max-width: 375px) {
    .header_container__Q5nEt {
        padding: 0 15px;
    }
    .header_title__3LD_Y{
        font-size: 18px;
    }
}

@media only screen and (max-width: 320px) {
    .header_container__Q5nEt {
        padding: 0 15px;
    }
    .header_title__3LD_Y{
        font-size: 18px;
    }
}


.navbar_container__1Ih4h {
  display: flex;
  position: relative;
  font-family: "Lato", sans-serif;
  margin-top: 20px;
}

.navbar_items__1p2Kb * {
  font-family: "Lato", sans-serif;
}
.navbar_items__1p2Kb {
  position: relative;
  display: flex;
  width: 100%;
  margin-left: -20px;
  padding: 30px 0;
  overflow-x: scroll;
  overflow: auto;
}

.navbar_items__1p2Kb::-webkit-scrollbar {
  display: block !important;
  height: 5px;
  visibility: hidden;
}

.navbar_items__1p2Kb::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: none;
  background-color: #6b6b6b63;
}
.navbar_nav_item__1qgsS {
  min-width: -webkit-max-content;
  min-width: max-content;
  display: flex;
  text-align: center;
  padding: 10px 20px;
  margin: 0 5px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  border-radius: 32px;
  text-decoration: none;
  color: #777777;
  background: #242425;
}
.navbar_nav_item__1qgsS:hover {
  cursor: pointer;
  background: #313131;
}
.navbar_nav_item__1qgsS.navbar_activeCategory__3qrDl {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.12) 9.82%,
      rgba(255, 255, 255, 0) 92.86%
    ),
    #d7141d !important;
  border: none;
  color: white;
}
.navbar_nav_item__1qgsS.navbar_activeCategory__3qrDl:hover {
  color: #fff;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.12) 9.82%,
      rgba(255, 255, 255, 0) 92.86%
    ),
    #d7141d;
}

@media (max-width: 900px) {
  .navbar_items__1p2Kb {
    width: -webkit-max-content;
    width: max-content;
    overflow-x: scroll;
    padding-bottom: 10px;
    /* background-color: #d7141d; */
  }
  .navbar_container__1Ih4h {
  }
}

@media only screen and (max-width: 850px) {
  .navbar_container__1Ih4h {
    padding: 0 70px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 550px) {
  .navbar_container__1Ih4h {
    max-width: 400px;
    padding: 0;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 425px) {
  .navbar_container__1Ih4h {
    padding: 0 15px;
  }
  .navbar_title__1dAxC {
    font-size: 18px;
  }

  .navbar_items__1p2Kb {
    margin: 0 0.5px;
  }
}

@media only screen and (max-width: 375px) {
  .navbar_container__1Ih4h {
    padding: 0 15px;
  }
  .navbar_title__1dAxC {
    font-size: 18px;
  }
}

@media only screen and (max-width: 320px) {
  .navbar_container__1Ih4h {
    padding: 0 15px;
  }
  .navbar_title__1dAxC {
    font-size: 18px;
  }
}

.filter_container__2hvV_{
    position: fixed;
    transition : all .3s ease;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;  
    top: 50%;
    right: 0;
    padding-top: 20px;
    padding-bottom:  20px;
    background: #111112;
    opacity: 0.98;
    z-index: 100;
    font-family: "Lato", sans-serif;
}

.filter_result__1FZZK{
    display: flex;
    flex-wrap: wrap;
}
.filter_result__1FZZK a {
    margin-right: 10px;
}

.filter_containerTitle__1BXKf{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.filter_title__WLdPh{
    display: flex;
    font-weight: bold;
    font-size: 30px;
    color: white;
    line-height: 25px;
    align-items: center;
}

@media (max-width:900px) {
    .filter_title__WLdPh{
        font-size: 18px;
    }
}

.filter_favourites__2m4lJ img:hover{
    cursor: pointer;
}

/* Navigation  */
.filter_navigation__QcoMy{
    display: flex;
    padding:10px 0;
}

.filter_tab_item__1S-6e{
    
    display: flex;
    margin: 0 10px;
}

.filter_buttonInActive__1q4j4{
    background:#1e1e1f;
    border-radius:20px;
    color:#777777
}
.filter_buttonInActive__1q4j4:hover{
    background-color: #222222;
}

.filter_filterArea__2HrT1{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-left: 20px;
}
.filter_filterArea__2HrT1>div{
    display: flex;
    text-align: center;
    width: calc(100%/4);
    margin-bottom: 25px;
    -webkit-user-select: none;
            user-select: none;
}

@media screen and (max-width:1024px) {
    .filter_filterArea__2HrT1>div{
        width: calc(100%/3);
    }
}
@media screen and (max-width:760px) {
    .filter_filterArea__2HrT1>div{
        width: calc(100%/2);
    }
}

.filter_tab_item__1S-6e>button{
    width: 80px;
}
.checkInput_item__37wzc{
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin:5px 0;
    background-color: inherit;
    color: #949495;
}
.checkInput_item__37wzc:hover{
    cursor: pointer;
}
.checkInput_item__37wzc label{
    display: flex;
    padding-left: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
}
.checkInput_item__37wzc input{
    width: 15px;
    background: inherit;
    height: 15px;
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkInput_item__37wzc label:hover,.checkInput_item__37wzc input:hover{
    cursor: pointer;
}
.checkInput_item__37wzc input::after, .checkInput_item__37wzc input::before{
    display: flex;
    position: absolute;
    content: '';
    width: 15px;
    border-radius: 50%;
    height: 15px;    
}

.checkInput_checked__2DSZ9{
    display: flex;
    position: absolute;
    content: '';
    left: 0;
    -webkit-transform: translate(-5.9px, -0.5px);
            transform: translate(-5.9px, -0.5px);
    z-index: 0;
}

.checkInput_item__37wzc input::before{
    display: none;
}

.checkInput_dark__2lhaK input:after{
    border: 2px solid #949495;
    background-color: rgb(41, 40, 40);
}   

.checkInput_light__254MZ input:after{
    border: 1px solid #949495;
    background-color: rgb(255, 255, 255);
}

.checkInput_dark__2lhaK input:checked::after{
    border: 2px solid white;
    background-color:initial;
}

.checkInput_light__254MZ input:checked::after{
    border: 1px solid white;
    background-color:initial;
}


.loader_loadercover__2-wca {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 1);
    z-index: 999999999;
    overflow: hidden;
}

.loader_loaderbody__11O8W {
    width: 100px;
    height: 100px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    -webkit-animation: loader_spin__3-xy6 1s linear infinite;
            animation: loader_spin__3-xy6 1s linear infinite;
}
.loader_logoBlock__Zn4QV{
    margin-bottom: 40px;
}
.loader_logo__34toM{
    width: 200px;
    object-fit: contain;
}
@-webkit-keyframes loader_spin__3-xy6 { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes loader_spin__3-xy6 { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.movieInfo_container__25CEF {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  color: #fff;
}
.movieInfo_movieWrapper__3UP8k {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.movieInfo_movieIntro__1iK03 {
  display: flex;
}
.movieInfo_movieTitle__gcvBG {
  display: flex;
  width: 100%;
}
.movieInfo_filmBio__30Yot {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.movieInfo_moviePicture__3VV_H img {
  width: 220px;
  height: 300px;
  border-radius: 10px;
}

.movieInfo_movieInfo__3Yz05 {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF .movieInfo_info_text__2gBoS {
  margin-bottom: 14px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #bbbbbb;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

.movieInfo_img_rate__u0S_C {
  display: none;
}

.movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF div {
  height: 22px;
  display: flex;
  align-items: baseline;
}

.movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF div img {
  height: 14px;
  margin-right: 10px;
}

.movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF span {
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.movieInfo_genre__2V9qo {
  position: relative;
  width: 100%;
  height: fi;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  margin-bottom: 20px;
  overflow: auto;
}

.movieInfo_genre__2V9qo a {
  display: flex;
  justify-content: center;
  font-style: normal;

  font-size: 14px;
  text-decoration: none;
  margin: 5px 4px;
  color: white;
  padding: 2px 10px;
  font-weight: 400;
  background-color: rgb(32, 32, 32);
  border-radius: 50px;
  line-height: 17px;
}

.movieInfo_genre__2V9qo.movieInfo_dark__2AgLy a {
  background-color: #fff;
  color: #777;
}

.movieInfo_movieIntro__1iK03 .movieInfo_ads__2xlWD {
  width: 33%;
  height: 160px;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 700;
}

.movieInfo_movieIntro__1iK03 .movieInfo_ads__2xlWD img {
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
}

.movieInfo_movieTitle__gcvBG {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 170%;
  color: #ffffff;
}

.movieInfo_playerWrapper__1_Qfd {
  margin: auto;
  margin-bottom: 40px;
}

.movieInfo_description__1MUUO {
  margin-top: 30px;
  display: block;
  padding: 10px 0;
  padding-right: 50px;
  overflow: hidden;
  color: white;
  position: relative;
  text-overflow: ellipsis;
  transition: height 0.3s ease;
}

.movieInfo_description__1MUUO p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.movieInfo_description__1MUUO div {
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 600;
  content: "ochish";
}

.movieInfo_description__1MUUO div:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1110px) {
  .movieInfo_container__25CEF {
    display: flex;
    flex-direction: column;
    max-width: 1024px;
    width: 100%;
    margin-bottom: 60px;
  }

  .movieInfo_movieWrapper__3UP8k {
    padding-top: 40px;
    width: 100%;
  }

  .movieInfo_moviePicture__3VV_H img {
    width: 200px;
    height: 280px;
  }

  .movieInfo_movieTitle__gcvBG h3 {
    font-size: 32px;
  }

  .movieInfo_movieInfo__3Yz05 {
    width: 60%;
    padding-top: 5px;
  }

  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF .movieInfo_info_text__2gBoS {
    margin-bottom: 0px;
    font-size: 15px;
  }

  .movieInfo_playerWrapper__1_Qfd {
    max-width: 1024px;
    width: 100%;
  }
}

@media only screen and (max-width: 970px) {
  .movieInfo_container__25CEF {
    max-width: 900px;
  }

  .movieInfo_moviePicture__3VV_H img {
    width: 190px;
    height: 260px;
  }

  .movieInfo_movieTitle__gcvBG h3 {
    font-size: 30px;
  }

  .movieInfo_playerWrapper__1_Qfd {
    max-width: 900px;
    width: 100%;
  }
}

@media only screen and (max-width: 840px) {
  .movieInfo_container__25CEF {
    display: flex;
    flex-direction: column;
    max-width: 760px;
    width: 100%;
  }

  .movieInfo_moviePicture__3VV_H img {
    width: 100%;
    height: auto;
  }

  .movieInfo_movieInfo__3Yz05 {
    width: 60%;
    padding-top: 5px;
  }

  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF .movieInfo_info_text__2gBoS {
    margin-bottom: 0px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }

  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF span {
    font-size: 14px;
  }

  .movieInfo_playerWrapper__1_Qfd {
    max-width: 760px;
    width: 100%;
  }

  .movieInfo_movieTitle__gcvBG {
    font-size: 15px;
  }
}

@media only screen and (max-width: 760px) {
  .movieInfo_container__25CEF {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
  }

  .movieInfo_movieIntro__1iK03 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px !important;
    padding-left: 5px;
  }

  .movieInfo_filmBio__30Yot {
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin-top: 20px;

    display: flex;
  }
  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF {
    flex-direction: column;
  }
  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF .movieInfo_info_text__2gBoS {
    font-size: 16px;
  }

  .movieInfo_moviePicture__3VV_H {
    width: 40%;
  }
  .movieInfo_movieInfo__3Yz05 {
    width: 60%;
    margin: 0;
    justify-content: flex-end;
  }
  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF span {
    font-size: 18px;
  }
  .movieInfo_genre__2V9qo {
    margin-top: 10px;
  }

  .movieInfo_movieIntro__1iK03 .movieInfo_ads__2xlWD {
    width: 100%;
    margin-top: 30px;
    height: 160px;
  }

  .movieInfo_description__1MUUO p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 460px) {
  .movieInfo_container__25CEF {
    width: 100%;
    padding-bottom: 100px;
  }

  .movieInfo_moviData__1VYEF > span {
    font-size: 16px;
  }

  .movieInfo_description__1MUUO {
    padding: 0 15px;
  }

  .movieInfo_movieIntro__1iK03 {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .movieInfo_filmBio__30Yot {
    width: 100%;
    padding-bottom: 100px;
    margin-top: 20px;
    display: flex;
    margin-right: auto;
  }

  .movieInfo_moviePicture__3VV_H .movieInfo_film_img__1Cz3E {
    position: relative;
  }

  .movieInfo_moviePicture__3VV_H .movieInfo_film_img__1Cz3E img {
    width: 100%;
    height: auto;
  }

  .movieInfo_moviePicture__3VV_H .movieInfo_film_img__1Cz3E .movieInfo_img_rate__u0S_C {
    display: block;
    text-align: center;
    position: absolute;
    bottom: -100px;
    width: 100%;
    background: #d7141d;
    opacity: 0.7;
    padding: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;
  }

  .movieInfo_moviePicture__3VV_H .movieInfo_film_img__1Cz3E .movieInfo_img_rate__u0S_C p {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }

  .movieInfo_moviePicture__3VV_H .movieInfo_film_img__1Cz3E .movieInfo_img_rate__u0S_C img {
    width: 86.8px;
    height: 14px;
  }

  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF .movieInfo_info_text__2gBoS {
    font-size: 14px;
  }

  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF span {
    font-size: 15px;
  }

  .movieInfo_movieWrapper__3UP8k {
    padding-top: 30px;
  }

  .movieInfo_movieIntro__1iK03 .movieInfo_ads__2xlWD {
    width: 100%;
    height: 160px;
    margin: 0;
  }

  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF {
    display: flex;
    flex-direction: column;
  }

  .movieInfo_movieInfo__3Yz05 .movieInfo_moviData__1VYEF .movieInfo_info_text__2gBoS {
    color: #c6a1a1;
  }

  .movieInfo_genre__2V9qo {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0px;
  }

  .movieInfo_movieInfo__3Yz05 .movieInfo_rating_film__2g5_0 {
    display: none;
  }

  .movieInfo_playerWrapper__1_Qfd {
    max-width: 380px;
    width: 100%;
  }
}

.triller_container__11NG4 {
  position: relative;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
          user-select: none;
  font-family: "Lato", sans-serif;
  overflow: hidden;
}

.triller_carousel__2nAuY {
  position: relative;
  display: flex;
  height: 52vh;
}
.triller_slideBox__2jgIS {
  height: 100%;
  width: 100%;
  position: relative;
}

.triller_carouselWay__1QBXg {
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  display: flex;
}

.triller_carouslItem__30aeE {
  width: 50%;
  height: 100%;
  display: flex;
}


/* Controls */
.triller_control__2Kj3c {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  width: 100%;
  font-family: "Lato", sans-serif;
}
.triller_info__3kYAe {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.triller_counter__2fXi8 {
  display: flex;
  align-items: center;
}

.triller_name__1uRqq {
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 31px;
  color: #ffffff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* display: flex; */
  /* align-items: flex-end; */
}
.triller_name__1uRqq span {
  /* display: inline; */
  font-weight: normal;
  /* margin-bottom:-10px; */
  margin-left: 10px;
  font-size: 14px;
  line-height: 17px;
}
.triller_subInfo__3tOdX {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.triller_genre__rb3yN {
  font-family: "Lato", sans-serif;
  margin-top: 10px;
  align-items: flex-start;
  max-width: 80%;
  position: static;
}
.triller_country__1G5J0 {
  margin-right: 10px;
  margin-top: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
}

@media screen and (max-width: 1190px) {
  .triller_control__2Kj3c {
    flex-direction: column;
  }
  .triller_counter__2fXi8 {
    margin: 0px auto;
    /* margin-top: 0px; */
    margin-bottom: -30px;
  }
  .triller_name__1uRqq span {
    font-size: 10px;
  }
  .triller_genres__2ZUhO {
    margin-top: 10px;
  }
  .triller_subInfo__3tOdX {
    align-items: flex-start;
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {
  .triller_control__2Kj3c {
    margin-top: 30px;
  }
  .triller_name__1uRqq {
    font-size: 29px;
  }

  .triller_control__2Kj3c .triller_counter__2fXi8 {
    margin: 0 auto;
  }
}

@media screen and (max-width: 761px) {
  .triller_carousel__2nAuY {
    padding: 0 40px;
  }

  .triller_control__2Kj3c {
    padding: 0 40px;
    bottom: 0;
  }

  .triller_name__1uRqq {
    font-size: 20px;
  }

  .triller_country__1G5J0 {
    font-size: 16px;
  }
}

@media screen and (max-width: 850px) {
  .triller_carousel__2nAuY {
    padding: 0 70px;
  }

  .triller_control__2Kj3c {
    padding: 0 70px;
  }
}

@media screen and (max-width: 460px) {
  .triller_control__2Kj3c {
    margin-bottom: 10px;
  }

  .triller_carousel__2nAuY {
    padding: 0 10px;
  }

  .triller_control__2Kj3c {
    padding: 0 22px;
  }

  .triller_control__2Kj3c .triller_counter__2fXi8 {
    margin: 0 auto;
  }

  .triller_counter__2fXi8 .triller_buttons__10YFO {
    margin: 0 40px;
  }
}


.genreMovie_container__wLKca {
    display: flex;
    position: relative;
    font-family: "Lato", sans-serif;
    margin-top: 20px;
    
}

.genreMovie_result__1KQwr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.moviePlayerContainer_container__1zmud {
  padding-top: 30px;
  overflow: visible;
  font-family: "Lato", sans-serif;
}

.moviePlayerContainer_topBar__UQNC3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0;
  align-items: center;
  padding-top: 28px;
}
.moviePlayerContainer_title_films__1tQn1 {
  display: flex;
  align-items: baseline;
}
.moviePlayerContainer_title_films__1tQn1 p {
  color: #bbbbbb;
  margin-right: 8px;
}

.moviePlayerContainer_title_films__1tQn1.moviePlayerContainer_black__229Fp p {
  color: rgba(187, 187, 187, 1);
}

.moviePlayerContainer_serialItems__1eTE6 {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.moviePlayerContainer_bottom__1aX3N {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.moviePlayerContainer_pagination__3rmUh {
  display: flex;
  padding: 5px 0;
  justify-content: center;
  width: 100%;
}

.moviePlayerContainer_title_films__1tQn1 h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-weight: 900;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
}

.moviePlayerContainer_title_films__1tQn1.moviePlayerContainer_black__229Fp h3 {
  color: rgba(17, 17, 18, 1);
}

.moviePlayerContainer_additional_functions__qiOfN {
  display: flex;
  margin-left: auto;
}
.moviePlayerContainer_additional_functions__qiOfN > div {
  margin-left: 20px;
}

.moviePlayerContainer_configures__23fsX {
  display: flex;
}

.moviePlayerContainer_dropdown__a00Bq {
  border-radius: 19px;
  margin-right: 10px;
}
.moviePlayerContainer_icon__1oJbw {
  margin-right: 5px;
}
.moviePlayerContainer_btn__3V515 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #777777;
}

.moviePlayerContainer_playerArea__1vLE0 {
  display: flex;
  width: 100%;
  max-height: 90vh;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.moviePlayerContainer_cover__2cllq {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.moviePlayerContainer_cover__2cllq img {
  width: 100%;
}

.moviePlayerContainer_controlBtn__RJtle {
  position: absolute;
  bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  z-index: 1;
}

.moviePlayerContainer_additional_functions__qiOfN.moviePlayerContainer_black__229Fp > div > .moviePlayerContainer_btn__3V515 {
  background: #fff;
}

@media screen and (max-width: 760px) {
  .moviePlayerContainer_btn__3V515 {
    font-size: 10px !important;
  }

  .moviePlayerContainer_title_films__1tQn1 > h3 {
    font-size: 24px;
  }

  .moviePlayerContainer_playerArea__1vLE0 {
    /* max-height: 20vh; */
  }

  .moviePlayerContainer_cover__2cllq img {
    /* max-height: 20vh; */
  }
}

@media screen and (max-width: 450px) {
  .moviePlayerContainer_btn__3V515 > img {
    width: 10px;
  }

  .moviePlayerContainer_title_films__1tQn1 > h3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  .moviePlayerContainer_btn__3V515 > p {
    display: none;
  }
}

.dropDown_dropdown__1ay7s{
    -webkit-user-select: none;
            user-select: none;
    display: flex;
    position: relative;
}

.dropDown_itemOne__Y0rIR{
    display: flex;
    align-items: center;
    padding:10px 14px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
}
.dropDown_itemOne__Y0rIR svg{
    margin-left: 10px;
}
.dropDown_itemOne__Y0rIR:hover{
    cursor: pointer;
}
.dropDown_items__Cf2fs{
    overflow: hidden;
    display: flex;
    position: absolute;
    top:100%;
    right: 0;
    flex-direction: column;
    background: #1f1c1c;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #1D2C3C;
    z-index: 9999;
}

.dropDownItem_dropdownItems__2Emoh{
    padding: 10px;
    border-bottom: 1px solid #E0DFDF;;
}

.dropDownItem_dropdownItems__2Emoh:hover{
    background: #c5c5c5;
    cursor: pointer;
}
.moviePlayer_container__3coje{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: black;
    justify-content: center;
}
.moviePlayer_container__3coje video{
    display: flex;
    max-width: 100%;
    max-height: 90vh;
    height: 100%;
}

video::-webkit-media-controls-panel{
    width: 100%;
}

.moviePlayer_playPause__3bW_N{
    color: white;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);

}
.moviePlayer_controlBtns__1jbzw{
    display: flex;
    position: absolute;
    width: 100%;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 4;
}
.moviePlayer_controls__1XpJJ{
    margin-top: auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background-color: rgba(2, 2, 2, 0.774);
    min-height: 20px;
    width: 100%;
    z-index: 9;
    padding: 2px;
    padding-bottom: 10px;
    -webkit-user-select: none;
            user-select: none;
    color: wheat;
    transition: all .3s;
}
.moviePlayer_hiddenControl__1O61W{
    opacity: 0;
}
.moviePlayer_hideCursor__2vBBX{
    cursor: none;
}
.moviePlayer_playerVisible__pDQ3C{
    display: flex;
    width:100%;
    height:100%;
}
.moviePlayer_playingStatus__2j3Ky{
    display: flex;
    padding: 5px;
    border-radius: 50%;
}
.moviePlayer_playingStatus__2j3Ky:hover{
    cursor: pointer;
    background-color: rgba(61, 61, 61, 0.281);
}

.moviePlayer_duration__1iieY{
    padding: 0 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #C4C4C4;
}

.moviePlayer_prevTap__NXoqC, .moviePlayer_nextTap__I-QWG{
    z-index: 8;
    position: absolute;
    width: 40%;
    height: 100%;
}

.moviePlayer_prevTap__NXoqC{
    left: 0;
}
.moviePlayer_nextTap__I-QWG{
    right: 0;
}

.videoVolume_container__1cZ9i{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.videoVolume_degree__msoIz{
    display: flex;
    height: 15px;
    margin-left: 3px;
}
.videoVolume_container__1cZ9i img:hover{
    cursor: pointer;
}
.videoVolume_degree__msoIz:hover{
    cursor: pointer;
}
.videoVolume_degree__msoIz div{
    margin-right: 1px;
    display: flex;
    height: 100%;
    width: 4px;
    background: #777777;
    border-radius: 2px;
}
.videoVolume_degree__msoIz div:hover{
    cursor: pointer;
}

.seekbar_seekbar__RhXjK{
    display: flex;
    position: relative;
    width: calc(100% - 250px);
    margin: 0 15px;
}
.seekbar_seekbar__RhXjK input[type='range']{
    -moz-appearance: none;
    opacity: 0;
    width: 100%;
    -webkit-appearance: none;
    background: #f0f0f0;
    height: 6px;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
}
.seekbar_seekbar__RhXjK input[type='range']::-webkit-slider-thumb{
    opacity: 1;
    -webkit-appearance: none;
    background: #D7141D;
    height: 13px;
    width: 13px;
    border-radius: 50%;
}

.seekbar_filled__T17fp, .seekbar_seek__2M9Dd{
    display: flex;
    align-items: center;
    height: 6px;
    border-radius: 4px;
    position: absolute;
    
    z-index: -1;
}
.seekbar_filled__T17fp{
    background-color: #D7141D;
}
.seekbar_seek__2M9Dd{
    width: 100%;
    background-color: #C4C4C4;
}
.seekbar_filled__T17fp::after{
    content: '';
    position: absolute;
    right: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #D7141D;
}
.actor_container__qg27u {
  display: flex;
  flex-direction: column;
  background-color: red;
}
.actor_sliderContainer__1IoaK {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

.actor_notFount__eN8ey {
  font-family: "Lato", sans-serif;
}

.actor_typeButton__5iC8v {
  display: flex;
  margin-bottom: 30px;
}

.actor_typeButton__5iC8v * {
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.actor_actors__pN7Lt {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 0 50px;
}

@media screen and (max-width: 760px) {
  .actor_sliderContainer__1IoaK {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 450px) {
  .actor_typeButton__5iC8v button {
    font-size: 10px;
  }

  .actor_sliderContainer__1IoaK {
    width: 90%;
    margin: 0 auto;
  }
}

.actorItem_container__2AbsD {
  position: relative;
  display: flex;
  width: 120px;
  height: 178px;
  margin-left: 37px;
  font-family: "Lato", sans-serif;
}

.actorItem_container__2AbsD:first-child {
  padding-left: 0;
  margin-left: 0;
}

.actorItem_container__2AbsD:last-child {
  padding-right: 0;
}

.actorItem_item__NFKWO {
  display: flex;
  flex-direction: column;
}

.actorItem_image__2JWmD {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}

.actorItem_image__2JWmD .actorItem_img__esEIu {
  height: 100%;
  width: 100%;
  position: absolute;
}

.actorItem_name__2piqV {
  font-family: Lato;
  font-weight: 900;
  font-size: 17px;
  line-height: 17px;
  margin-bottom: 5px;
  color: white;
}

.actorItem_role__2plES {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #c4c4c4;
}

@media screen and (max-width: 450px) {
  .actorItem_image__2JWmD {
    width: 100px;
    height: 100px;
  }
}

.comments_container__26CgP {
  position: relative;
  display: flex;
  padding-bottom: 30px;
  padding-top: 50px;
}
.comments_container__26CgP * {
  font-family: "Lato", sans-serif;
}
.comments_commentContainer__3DaPx {
  display: flex;
}

.comments_comments__1c0Eo {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-right: 70px;
  font-family: Montserrat;
  font-style: normal;
  padding-bottom: 14px;
}
.comments_addComment__D0juF {
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 40%;
}
.comments_comments__1c0Eo .comments_title__3NQWD {
  font-weight: bold;
  font-size: 27px;
  line-height: 33px;
  font-family: Montserrat;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #ffffff;
}

.comments_comments__1c0Eo .comments_description__jHCok {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #bbbbbb;
}
.comments_comments__1c0Eo .comments_slider__gLm7I {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.comments_addComment__D0juF .comments_label__1CDAD {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: uppercase;

  color: #ffffff;
}
.comments_addComment__D0juF textarea {
  resize: none;
  outline: none;
  border: none;
  height: 205px;
  padding: 10px;
  color: white;
  background: rgba(196, 196, 196, 0.05);
  border-radius: 8px;
}
.comments_addComment__D0juF .comments_button__3BlVz {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1025px) {
  .comments_comments__1c0Eo .comments_slider__gLm7I {
    display: flex;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 760px) {
  .comments_commentContainer__3DaPx {
    flex-direction: column;
    padding: 0 10px;
  }

  .comments_title__3NQWD {
    font-size: 20px !important;
  }

  .comments_description__jHCok {
    font-size: 14px !important;
  }
  .comments_comments__1c0Eo {
    width: 100%;
    padding-right: 0;
  }
  .comments_addComment__D0juF {
    padding-left: 0;
    width: 100%;
  }
  .comments_addComment__D0juF .comments_button__3BlVz {
    justify-content: center;
  }
}

.commentItem_container__1xm0y{
    margin-top: 20px;
    display: flex;
    height: 205px;
    background: rgba(196, 196, 196, 0.05);
    border-radius: 8px;
    padding:30px 20px ;
    color: white;
}
.commentItem_author__1xlCQ, .commentItem_comment__2N_VR{
    padding:0 20px;
}
.commentItem_divider__1og0R{
    display: flex;
    height: 100%;
    width: 1px;
    background-color: white;
}
.commentItem_author__1xlCQ{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */ 

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: #F6F6F6;
 }
 .commentItem_comment__2N_VR{
    font-weight: 300;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */
    overflow: hidden;
    overflow-y: scroll;
    
    color: #F6F6F6;
 }
.shareMovie_modal__2kpZc {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.shareMovie_modal_container__1eiwS {
  position: relative;
  width: 30%;
  height: 30vh;
  margin: 0 auto;

  margin-top: 15%;
  border-radius: 5px;
  background-color: #fff;
}


.shareMovie_modal_body__3HfY5 {
  padding: 30px;
  font-family: "Lato", sans-serif;
}

.shareMovie_modal_header__YNzpf {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.shareMovie_close_button__2po03 {
  width: 25px;
  height: 25px;
  background-color: #f8f9fc;
  border: none;
}
.shareMovie_sharing_container__1Lyy9 {
  width: 100%;
  display: flex;
  margin-top: 20px;
  overflow: auto;
}

.shareMovie_sharing_container__1Lyy9::-webkit-scrollbar {
  display: none;
}

.shareMovie_sharing_container__1Lyy9 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.shareMovie_sharing_container__1Lyy9 .shareMovie_share_item__bWrBN {
  margin-left: 20px;
  text-align: center;
}

.shareMovie_sharing_container__1Lyy9 .shareMovie_share_item__bWrBN:first-child {
  margin: 0;
}

.shareMovie_sharing_container__1Lyy9 .shareMovie_share_item__bWrBN p {
  margin-top: 5px;
}

.shareMovie_link_copy__2aaBM {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #777;
  overflow: hidden;
}

.shareMovie_share_link_input__2g-tQ {
  width: 70%;
  padding: 5px;
  border: none;
}

.shareMovie_copy_button__39z-1 {
  border: none;
  background: transparent;
  font-weight: bold;
}

.shareMovie_start_at__18M60 {
  display: flex;
  align-items: center;
  margin-top: 20px;
  -webkit-user-select: none;
          user-select: none;
}

.shareMovie_input_start_at__3GxmJ {
  margin-left: 7px;
}

.shareMovie_input_start_at__3GxmJ input {
  width: 50px;
  border: none;
  outline: none;
  border-bottom: 1px solid #777;
  margin-left: 5px;
}





.live-close {
    background-color: #fff;
    border: none;
    outline: none;
    border: 1px solid black;
    width: 29px;
    height: 29px;
    border-radius: 5px;
  }
  .share__live-wrapper {
    padding: 30px;
    font-family: "Lato", sans-serif;
      background-color: #fff;
      border-radius: 5px;
      height: 280px;
  }
  .live__container {
    position: relative;
    max-width: 350px;
    min-width: 450px;
    height: 30vh;
    margin: 0 auto;
  
    margin-top: 15%;
    border-radius: 5px;
    background-color: #fff;
  }
  @media screen and (max-width: 500px) {
    .live__container {
        min-width: 400px;
    }
  }
  @media screen and (max-width: 410px) {
    .live__container {
        min-width: 370px;
    }
  }
  @media screen and (max-width: 410px) {
    .live__container {
        min-width: 350px;
    }
  }
  @media screen and (max-width: 350px) {
    .live__container {
        min-width: 330px;
    }
  }
  @media screen and (max-width: 330px) {
    .live__container {
        min-width: 300px;
    }
  }

.series_seasonList__yevGT{
    list-style: none;
    margin: 0;
    display: flex;
    padding: 0 120px;
}
.series_liElem__bDujn{
    color: #fff;
    margin-right: 30px;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
    transition: all .3s;
}
.series_liElem__bDujn.series_active__1RcQP{
    border-bottom: 1px solid red;
    color: red;
}
.series_serActive__3_yeF{
    color: darkred;
}
.series_seriesList__3qe0_{
    padding: 0 120px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.series_seriesBlock__2x3DT{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.series_seriesItem__31i4h{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 20px;
    cursor: pointer;
    transition: all .3s;
}
.series_seriesItemActive__1y46k{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.series_seriesImg__18KKh{
    width: 100px;
    object-fit: contain;
    border-radius: 5px;
    transition: all .3s;
}
.series_seriesImgActive__Wnz7w{
    border: 1px solid darkred;
}
.series_seriesTxt___wDWA{
    color: #FFFFFF;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    transition: all .3s;
}
.series_seriesTxtActive__3YWpm{
    color: darkred;
}

.livePlayerContainer_description__67Fbh {
}

.livePlayerContainer_description__67Fbh p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.livePlayerContainer_description__67Fbh div {
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 600;
  content: "ochish";
}

.livePlayerContainer_playerArea__2TFAZ {
  /* min-height: 90vh; */
  max-height: 90vh;
  overflow: hidden;
  border-radius: 10px;
}

.livePlayerContainer_description__67Fbh div:hover {
  cursor: pointer;
}

.livePlayerContainer_container__2G6RY .livePlayerContainer_video__3pHLH{
  display: flex;
  max-width: 100%;
  min-height: 90vh;
  max-height: 90vh;
  height: 100%;
}

.livePlayerContainer_video__3pHLH::-webkit-media-controls-panel{
  width: 100%;
}


.livePlayerContainer_btn__CGedL{
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border: none;
  outline: none;
  background: #D7141D;
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  transition: all .1s ease-in-out;
}
.livePlayerContainer_btn__CGedL:hover{
  background: #e72e37;
  cursor: pointer;
}
.livePlayerContainer_btn__CGedL:active{
  background: #9c080f;
}



/* The Modal (background) */
.livePlayerContainer_modal__1WU-m {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.livePlayerContainer_modal_content__2Tt2W {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  max-width: 340px;
  margin-top: 85px;
  border-radius: 9px;
}

/* The Close Button */
.livePlayerContainer_close__1dQQF {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.livePlayerContainer_close__1dQQF:hover,
.livePlayerContainer_close__1dQQF:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.live__input {
    border: none;
    outline: none;
    padding-left: 15px;
    
    width: 250px;
    height: 30px;
    border: 1px solid black;
    border-radius: 15px;
  }
  .live__textarea {
    border: none;
    outline: none;
    margin-top: 15px;
    padding-left: 15px;
    padding: 15px;
    max-width: 250px!important;
    height: 120px;
    border: 1px solid black;
    border-radius: 15px;
  }
  .live__btn {
      margin-right: auto;
    border: none;
    margin-top: 15px;
    outline: none;
    padding: 8px 29px;
    border: 1px solid black;
    border-radius: 15px;
    color: white;
    background-color: red;
  }
.auth_container__1nJnu {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  overflow: hidden;
}
.auth_area__39GiB {
  width: 100%;
  min-height: 100%;
  display: flex;
}

.auth_fields__ZOHzP {
  width: 50%;
  height: 100%;
  padding: 30px 120px;
  padding-right: 160px;
  color: white;
}

.auth_mask__22Scz {
  width: 50%;
}
.auth_mask__22Scz img {
  min-height: 90vh;
}

@media screen and (max-width: 1024px) {
  .auth_fields__ZOHzP {
    padding: 30px 17px;
    padding-right: 100px;
    color: white;
  }
}
@media screen and (max-width: 760px) {
  .auth_fields__ZOHzP {
    padding-right: 17px;
    width: 100%;
  }
  .auth_area__39GiB {
    flex-direction: column;
  }
  .auth_mask__22Scz {
    width: 100%;
    margin-top: 50px;
  }
}

.enterpassword_navigation__2_MWj{
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
}

.enterpassword_navigation__2_MWj img:hover{
    cursor: pointer;
    box-shadow: 0 0 4px rgb(53, 53, 53);
    background-color: rgb(24, 24, 24);
    border-radius: 50%;
}


.enterpassword_title__ofyjr{
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

.enterpassword_description__2Yc9i{
    margin-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #A2A2A2;
}
.createaccaunt_navigation__23AiV{
    display: flex;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
}

.createaccaunt_navigation__23AiV img:hover{
    cursor: pointer;
    box-shadow: 0 0 4px rgb(53, 53, 53);
    background-color: rgb(24, 24, 24);
    border-radius: 50%;
}

.createaccaunt_title__yhqVf{
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: bold;
    align-items: center;
    margin-bottom: 30px;
    font-family: 'Lato', sans-serif;
}

.createaccaunt_regLink__1EWx8 {
    color: red;
    text-decoration: none;
}

.createaccaunt_regLink__1EWx8:hover {
    text-decoration: underline;
}

.createaccaunt_title__yhqVf div:nth-child(1){
    
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}
.createaccaunt_title__yhqVf div:nth-child(2){
    font-size: 16px;
    line-height: 18px;

    color: #D7141D;
}
.createaccaunt_title__yhqVf div:nth-child(2):hover{
    text-decoration: underline;
    cursor: pointer;
}
.login_navigation__l33j-{
}
.login_title__2Pa4z{
    font-family: 'Lato', sans-serif
}

.login_buttonLink__2dzqD{
    font-size: 16px;
    line-height: 18px;
    margin-top: 30px;
    
    color: #D7141D;
}
.login_buttonLink__2dzqD:hover{
    cursor: pointer;
    text-decoration: underline;
}

.login_regLink__on3im {
    color: red;
    text-decoration: none;
}

.login_regLink__on3im:hover {
    text-decoration: underline;
}
.inputProfile_container__3cyCT{
    display: flex;
    width: 100%;
    position: relative;
    padding: 10px 0px;
    flex-direction: column;
}

.inputProfile_title__348hI{
    font-family: LatoRegular;
    padding-bottom: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #949494;
    font-family: 'Lato', sans-serif;
}

.inputProfile_input__3Jzwy{
    display: flex;
    width: 100%;
    transition: border .4s ease-in-out;
    border: none;
    background: rgba(255, 255, 255, 0.06);
    padding: 10px 10px;
    padding-right: 40px;
    font-size: 19px;
    outline: none;
    font-size: 16px;
    line-height: 18px;
    color: white;
    border-radius: 28px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    font-family: 'Lato', sans-serif;
}

.inputProfile_input__3Jzwy::-ms-reveal, .inputProfile_input__3Jzwy::-ms-clear{
    display: none;
}

.inputProfile_eye__1rouP{
    position: absolute;
    right: 0;
    top: 55%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.inputProfile_eye__1rouP:hover{
    cursor: pointer;
}


.recover_navigation__2MVvQ{
}
.recover_title__ef2H9{
    font-family: 'Lato', sans-serif
}

.recover_buttonLink__28ugb{
    font-size: 16px;
    line-height: 18px;
    margin-top: 30px;
    
    color: #D7141D;
}
.recover_buttonLink__28ugb:hover{
    cursor: pointer;
    text-decoration: underline;
}

.recover_regLink__2Un1- {
    color: red;
    text-decoration: none;
}

.recover_regLink__2Un1-:hover {
    text-decoration: underline;
}
.verifyphone_navigation__m9QYD{
}
.verifyphone_title__1Ykhi{
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
}

.verifyphone_buttonLink__2bdrw{
    font-size: 16px;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #D7141D;
}
.verifyphone_buttonLink__2bdrw:hover{
    cursor: pointer;
    text-decoration: underline;
}


.verifyInput_container__1zNOv {
  display: flex;
}
.verifyInput_container__1zNOv input::-webkit-inner-spin-button,
.verifyInput_container__1zNOv input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.verifyInput_container__1zNOv input {
  appearance: none;
  -webkit-appearance: none;
  margin: 0 10px;
  display: flex;
  width: 60px;
  font-family: LatoRegular;
  transition: border 0.4s ease-in-out;
  border: none;
  background: rgba(255, 255, 255, 0.06);
  padding: 10px 20px;
  font-size: 19px;
  align-items: center;
  justify-content: center;
  outline: none;
  font-size: 16px;
  line-height: 18px;
  color: white;
  border-radius: 28px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
}
.verifyInput_container__1zNOv input:first-child {
  margin-left: 0;
}

.verifyInput_container__1zNOv input[type="number"]::-ms-reveal,
.verifyInput_container__1zNOv input[type="number"]::-ms-clear {
  display: none;
}

@media screen and (max-width: 1200px) {
  .verifyInput_container__1zNOv input {
    width: 50px;
    padding: 10px 12px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 760px) {
  .verifyInput_container__1zNOv input {
    margin: 0 10px;
    width: 60px;
    padding: 10 20px;
  }
}

@media screen and (max-width: 500px) {
  .verifyInput_container__1zNOv input {
    width: 50px;
    padding: 12px;
    margin: 0 5px;
  }
}

@media screen and (max-width: 382px) {
  .verifyInput_container__1zNOv input {
    width: 40px;
    padding: 7px;
    margin: 0 5px;
  }
}

.account_contentContainer__9nCt4{
    width: 100%;
    display: flex;
    font-family: 'Lato', sans-serif;
}
.account_content__Wm9_z{
    display: flex;
    width: 100%;
    min-height: 60vh;
}
.account_sidebar__1oiLD{
    display: flex;
    width: 40%;
}
.account_mask__3CGqw{
    position: relative;
    display: flex;
    width: 100%;
    height: 130px;
}
.account_mask__3CGqw>img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.account_imageAvatar__1ENrg{
    display: none;
}
.account_balance__2yDn4{
    display: none;
}

.account_pairs__CZ3bS .account_key__2OfyY{
    color: #777777;
    margin-right: 10px;
}

.account_pairs__CZ3bS .account_val__WtrP5 {
    color: #fff;
}

@media screen  and (max-width:768px) {
    .account_contentContainer__9nCt4{
        flex-direction: column-reverse;
    }
    .account_sidebar__1oiLD{
        width: 100%;
    }
    .account_mask__3CGqw{
        position: relative;
    }
    .account_imageAvatar__1ENrg{
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        align-items: center;
        padding: 10px;
        z-index: 6;
    }
    .account_usernameBox__3yCP3{
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        padding: 10px;
        color: white;
        margin-left: auto;
    }
    .account_balance__2yDn4{
        display: flex;
        padding: 10px;
        
        flex-direction: column;
        align-items: flex-end;
    }
    
    .account_pairs__CZ3bS{
        display: flex;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 19px;
    }
}
.profile_container__2AW-r {
  padding-left: 120px;
  position: relative;
  display: flex;
  width: 100%;
}

.profile_profileImage__2dcLS {
  position: absolute;
  top: 0;
  -webkit-transform: translateY(-55%);
          transform: translateY(-55%);
}

.profile_mainContainer__2UPRG {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 73px;
}

.profile_mainInfo__1JONB {
  display: flex;
  justify-content: space-between;
  padding: 35px 20px;
  border-bottom: 1px solid #3d3d3d;
}

.profile_nickName__7a2QR {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
}

.profile_pairs__2fL0I {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.profile_pairs__2fL0I .profile_key__3mx9F {
  color: #777777;
  margin-right: 10px;
}

.profile_pairsBottom__S9urX {
  margin-bottom: 20px;
}

.profile_addInfo__8ICG- {
  display: flex;
  flex-direction: column;
  padding: 35px 20px;
}

.profile_actions__3Pel6 {
  display: flex;
  justify-content: space-between;
  padding: 35px 20px;
  margin-top: auto;
}

.profile_buttonLink__1753z {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  margin-left: auto;
  display: flex;
  align-items: center;
  text-align: right;
  color: #d7141d;
}

.profile_buttonLink__1753z:hover {
  cursor: pointer;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.profile_editContent__1d05M {
  padding-top: 40px;
  padding-right: 90px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile_mainInput__2Uuol {
  flex-wrap: wrap;
  display: flex;
  padding: 0 20px;
}

.profile_setPass__3et8K {
  padding-left: 20px;
  color: white;
}

.profile_logoutBtn__3O4Z0 {
  cursor: pointer;
  color: red;
  display: none;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}

@media screen and (max-width: 768px) {
  .profile_profileImage__2dcLS {
    display: none;
  }
  .profile_mainInfo__1JONB {
    display: none;
  }
  .profile_container__2AW-r {
    padding: 10px;
  }
  .profile_addInfo__8ICG-,
  .profile_actions__3Pel6 {
    padding: 0;
  }
  .profile_mainContainer__2UPRG {
    padding: 10px 0;
  }
  .profile_editContent__1d05M {
    padding: 0;
  }

  .profile_logoutBtn__3O4Z0 {
    display: flex;
  }
}

.following_margin__3MGWv {
  margin-top: -35px;
}
.following_container__33EP2 {
  width: 100%;
  padding-bottom: 20px;
  font-family: "Lato", sans-serif;
}

.following_box__2MNbl {
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 420px;
  padding: 32px 25px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 15px;
  cursor: pointer;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}

.following_box__2MNbl:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);

}

.following_tarHeading__i-FiF {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
}
.following_tarDesc__22Tpt {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #777777;
  transition: all .3s;
}
.following_box__2MNbl:hover .following_tarDesc__22Tpt {
  color: #9a9a9a;
}
.following_tarPrice__7hSUp {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #FFFFFF;
}
.following_tarButton__ysPlS {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 19px;
  color: #D7141D;
  background: transparent;
  width: auto;
  border: none;
  cursor: pointer;
  display: inline;
}
.following_tarButton__ysPlS:hover {
  text-decoration: underline;
}

.following_messageBox__2NMRh {
  padding: 15px 25px;
  position: fixed;
  right: 25px;
  top: 25px;
  border-radius: 4px;
  transition: all .3s;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  opacity: 0;
}
.following_activeMessage__3_UFB {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}
.following_messageText__3rBqC {
  font-family: Roboto, sans-serif;
  font-size: 23px;
  color: #FFFFFF;
}
.following_errorMessage__3StHT {
  background: #980000;
}
.following_message__28tSY {
  background: #006410;
}
.following_activeCard__2Q8kK {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  height: -webkit-min-content;
  height: min-content;
}
.following_activeTitle__2xPnf {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 19px;
  margin: 0 0 15px;
}
.following_activeDescription__f7iUA {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #777777;
  margin: 0 0 15px;
}
.following_expDate__2zN9W {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
}
.following_activeMore__FS26w {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}
.following_moreText__37dZg {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: #D7141D;
}
.following_moreIcon__sWOVk {
  width: 20px;
  height: 20px;
  fill: #D7141D;
  margin-left: 10px;
}

.history_container__Oj19c {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    font-family: "Lato", sans-serif;
  }
  
  .history_box__3l2Kq {
    display: flex;
    flex-direction: column;
    padding: 28px;
    width: 50%;
    height: -webkit-min-content;
    height: min-content;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
  }
  
  .history_title__32Pyp {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    width: 59%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .history_title__32Pyp > div:nth-child(2) {
    font-weight: normal;
    font-size: 11px;
    line-height: 130%;
    color: #d7141d;
  }
  
  .history_description__bvHC8 {
    margin-top: 20px;
    display: flex;
    width: 59%;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 130%;
    color: #777777;
  }
  .history_costSection__2q6Sl {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    width: 50%;
  }
  
  .history_cost__39fme {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 130%;
    color: #d7141d;
  }


.history_pagination__1uSNg {
    display: flex;
    padding: 5px 0;
    justify-content: center;
    width: 100%;
  }

  .history_cost__39fme img {
    margin-right: 5px;
  }
  
  @media screen and (max-width: 770px) {
    .history_box__3l2Kq {
      width: 100% !important;
    }
  }
  
.profileSideBar_container__2k_dt {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 27px 120px 30px 30px;
  height: 100%;
  justify-content: space-between;
}
.profileSideBar_sidebarLinks__3KUAv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
}
.profileSideBar_sidebarLinks__3KUAv li {
  display: flex;
}
.profileSideBar_sideLink__4o_N1 {
  margin: 15px 0;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #777777;
}
.profileSideBar_sideLinkLight__TfbNA {
  margin: 15px 0;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #777777;
}
.profileSideBar_sideLinkActive__18Hw9 {
  color: white;
}
.profileSideBar_sideLinkActiveDark__2b7aJ {
  color: black;
}

/* exit button  */
.profileSideBar_exitbtn__2KXDg {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
  color: #777777;
}
.profileSideBar_exitbtn__2KXDg > img {
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  .profileSideBar_container__2k_dt {
    width: 100%;
    padding: 0px;
    overflow-x: auto;
    flex-direction: row;
  }
  .profileSideBar_exitbtn__2KXDg {
    display: none;
  }
  .profileSideBar_sidebarLinks__3KUAv {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 10px;
    flex-direction: row;
    align-items: center;
  }

  .profileSideBar_sidebarLinks__3KUAv li {
    padding: 10px 2px;
  }
  .profileSideBar_sidebarLinks__3KUAv li .profileSideBar_sideLink__4o_N1 {
    margin: 0;
    padding: 10px 30px;
    padding-top: 0;
    border-bottom: 2px solid #d6d6d6;
  }
  .profileSideBar_sideLinkActive__18Hw9 {
    color: #d7141d;
    border-bottom: 2px solid #d7141d !important;
  }

  .profileSideBar_exitbtn__2KXDg {
    display: none;
  }
}

.payment_container__2SR2C {
    width: 100%;
    padding: 30px 180px;
}
.payment_heading__4VOD7 {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
    margin: 0;
}
.payment_paymentBlock__10y0M {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 40px;
}
.payment_svgBlock__1aapD {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.payment_paymentSvg__3Rtiv {
    fill: #FFFFFF;
    height: 40px;
    object-fit: fill;
}
.payment_typesDiv__3exEf {
    margin: 15px 0;
}
.payment_img__2cikT {
    height: 40px;
    object-fit: contain;
}
.payment_label__1bLTV {
    cursor: pointer;
}
.payment_label__1bLTV div {
    padding: 10px;
    border-radius: 6px;
}
.payment_checkbox__IpSFK {
    display: none;
}
.payment_checkbox__IpSFK:checked+.payment_label__1bLTV div{
    background-color: #D7141D;
}
.payment_typeBlock__3w4lT {
    margin-right: 50px;
}
.payment_cardBlock__DaMFl {
    padding: 35px 30px;
    background: #111112;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}
.payment_details__3Nks1 {
    margin-top: 50px;
}
.payment_cardTitle__3v-FX {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #949494;
    margin-left: 15px;
}
.payment_cardInput__11o-L {
    padding: 10px 16px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.06);
    border-radius: 28px;
    border: none;
    outline: none;
    width: 330px;
    margin-top: 15px;
}
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}
.payment_cardAction__1kePc {
    width: 100%;
    text-align: right;
    margin-top: 25px;
}
.payment_cardBtn__3eHe7 {
    background: none;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #D7141D;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: right;
}
.payment_cardBtn__3eHe7:hover {
    text-decoration: underline;
}
.payment_balanceTxt__1btyw {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
}
.payment_summ__DbPrY {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-left: 10px;
}
.payment_cardMessage__8Fbxl {
    padding: 40px 0;
    margin-bottom: 25px;
    border-bottom: 1px solid #777777;
}

.payment_errorCardMessage__155qt {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    color: #777777;
    margin: 0;
}

.payment_addCardLink__1w646 {
    color: #D7141D;
    margin-left: 2px;
    cursor: pointer;
}

.payment_addCardLink__1w646:hover {
    text-decoration: underline;
}

.payment_addCardModal__yQZ1H {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment_modalBody__9eNmo {
    padding: 25px;
    background: #0C0C0D;
    border-radius: 8px;
}

.payment_card__1I1Ee {
    width: 350px;
    height: 184px;
    background: linear-gradient(217deg, rgba(101, 3, 3, 0.8), rgba(166, 17, 17, 0) 70.71%),
    linear-gradient(127deg, rgba(204, 16, 16, 0.8), rgba(0,255,0,0) 70.71%),
    linear-gradient(336deg, rgba(201, 72, 72, 0.8), rgba(0,0,255,0) 70.71%);
    padding: 20px;
    border-radius: 12px;
    display: flex;
    align-items: flex-end;
}

.payment_cardForm__1hBDS {
    width: 100%;
}

.payment_inputDiv__34MRt {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.payment_inputDiv__34MRt:not(:last-child) {
    margin-bottom: 10px;
}

.payment_inputSpan__lan7P {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.25);
    position: absolute;
    left: 0;
    bottom: 0;
}

.payment_inputSpan__lan7P::after {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    transition: all .2s;
    background: #ff0000;
}

.payment_cardNumberInput__3TaWo:focus + .payment_inputSpan__lan7P::after {
    width: 100%;
}

.payment_cardNumberInput__3TaWo {
    width: 293px;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 26px;
    color: #fff;
    outline: none;
    border: none;
    background: transparent;
    padding: 0 4px;
}
.payment_cardDateInput__10c3u {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    width: 67px;
    outline: none;
    border: none;
    background: none;
    padding: 0 4px;
}

.payment_cardDateInput__10c3u:focus + .payment_inputSpan__lan7P::after {
    width: 100%;
}

.payment_miniInputDiv__35Dap {
    position: relative;
}
.payment_submitBtn__3Atvy {
    width: 100%;
    height: 50px;
    background: #bb0000;
    color: #FFFFFF;
    outline: none;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
}
.payment_cardDetails__xREBs {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.payment_cardNumber__2SFHL {
    color: #FFFFFF;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.19em;
}
.payment_defaultCard__2zdJX {
    margin-bottom: 60px;
}
.payment_innerImg__sKMpi {
    width: 50%;
}
.payment_messageBlock__2lVm4 {
    position: fixed;
    right: 70px;
    top: 20px;
    padding: 20px 45px;
    background-color: #3c7200;
    border-radius: 8px;
    transition: all .3s;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0;
}
.payment_messageText__uUYuT {
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.payment_messageActive__3XKSy {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}
.payment_errorBlock__1fgp8 {
    background-color: #8c0000;
}
.payment_submitDiv__1x2w5 {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    padding: 40px;
    border-radius: 8px;
    background: #0C0C0D;
    flex-direction: column;
}
.payment_smsText__1R7QM {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
}
.payment_smsButton__3BNXE {
    outline: none;
    background: #bb0000;
    color: #FFFFFF;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    border: none;
    border-radius: 16px;
    width: 200px;
    height: 45px;
    margin-top: 20px;
    cursor: pointer;
}

@media (max-width: 425px) {
    .payment_container__2SR2C {
        padding: 30px 10px;
    }

    .payment_paymentBlock__10y0M {
        flex-direction: column;
    }

    .payment_card__1I1Ee {
        margin: 0 auto;
        width: 100%;
    }

    .payment_img__2cikT {
        width: 80%;
        height: auto;
        margin: 0 auto;
    }

    .payment_svgBlock__1aapD {
        width: 100%;
    }

    .payment_typeBlock__3w4lT {
        margin: 0;
        display: none;
    }

    .payment_paymentSvg__3Rtiv {
        width: 49%;
        height: auto;
    }

    .payment_imgDiv__3SrHJ {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .payment_heading__4VOD7 {
        display: none;
    }

    .payment_cardNumber__2SFHL {
        font-size: 22px;
    }

    .payment_cardInput__11o-L {
        width: 100%;
    }

    .payment_cardBlock__DaMFl {
        width: 100%;
    }

}
.favourites_container__EwpIt {
    display: flex;
    position: relative;
    font-family: "Lato", sans-serif;
    margin-top: 20px;
}


.favourites_title_category__27p-0{
    display: flex;
    font-weight: bold;
    font-size: 32px;
    margin: 0;
    margin-bottom: 20px;
    line-height: 29px;
    align-items: center;
}

.favourites_containerMovie__3u6xj {
    display: flex;
    flex-wrap: wrap;
    font-family: "Lato", sans-serif;
    justify-content: space-between;
}

@media only screen and (max-width: 815px) {
    .favourites_containerMovie__3u6xj {
        flex-direction: column;
        align-items: center;
    }
}
.notfound_container__3JGkX{
    width: 100vw;
    height: 90vh;
}

.notfound_backContainer__4tU3Y{
    width: 100vw;
    height: 100%;
}

.notfound_background__2DX3L{
    position: fixed;
    width: 100%;
    height: 100%;
}

.notfound_button__2ciup{
    width: 100%;
    z-index: 99;
    position: absolute;
    margin-top: -203px;
    justify-content: center;
    display: flex;
}

.notfound_button__2ciup a{
    text-decoration: none;
}
