.loadercover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 1);
    z-index: 999999999;
    overflow: hidden;
}

.loaderbody {
    width: 100px;
    height: 100px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    animation: spin 1s linear infinite;
}
.logoBlock{
    margin-bottom: 40px;
}
.logo{
    width: 200px;
    object-fit: contain;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }
